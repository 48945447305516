import clsx from 'clsx';
import { IconNoData } from 'icons';

export const NoData = ({ className }: { className?: string }) => {
    return (
        <div className={clsx("flex justify-center my-2", className)}>
            <div className="flex flex-col gap-2 items-center">
                <IconNoData />
                <p className="text-[#777E90] md:text-base text-xs font-normal">Chưa có dữ liệu</p>
            </div>
        </div>
    )
};
