import { DatePicker, Form, InputNumber, Select, Spin, Tooltip } from "antd";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import * as React from "react";

import { Button, CalendarV2Icon } from "@pancakeswap/uikit";
import { ORDER_TYPE, PAYMENT, formatNumberPayment } from "config";

import { CreateSellOrderType, useAccount, useInfoAsset, useNftShareholderBook, useTransaction } from "hooks";
import { useModal } from "hooks/use-modal";

import { EquivalentPayment, NoData, TitleTableMobile, ValueTableMobile, WrapperScroll } from "components";

import { useStateInfoAsset } from "state/state-info-asset";
import { InfoAssetType } from "type";
import { ItemInfo, WrapperItemInfo } from "../item-info";
import { Note } from "../note";

interface FormType {
    price: number;
    quantity: number;
    share: string;
    date: any;
}

export const ModalCreateSellOrder = () => {
    const [form] = Form.useForm();
    useStateInfoAsset();

    const { storeAccount } = useAccount();
    const { storeAsset } = useInfoAsset();
    const { handleCreateSellOrder, handleApprovalNft, loadingTx } = useTransaction();

    const [dataItemInfo, setDataItemInfo] = React.useState<InfoAssetType>();
    const [price, setPrice] = React.useState<number>(0);
    const [quantity, setQuantity] = React.useState<number>(0);
    const contractNft = useNftShareholderBook(dataItemInfo?.nft_address);
    const totalPrice = React.useMemo(() => new BigNumber(price || 0).multipliedBy(quantity || 0).toNumber(), [price, quantity]);

    const { handleCloseModal } = useModal();

    const handleOnChangeSelect = (stocksCode: string) => {
        const item = storeAsset.data.find((items: InfoAssetType) => items.stocks_code === stocksCode);
        setDataItemInfo(item);
        form.resetFields(["quantity"]);
    };

    const handleMaxQuantity = () => {
        form.setFieldsValue({
            quantity: dataItemInfo.quantity
        });
        setQuantity(dataItemInfo.quantity);
    };
    const onSubmit = async (value: FormType) => {
        const date = Math.floor(value.date.valueOf() / 1000);
        const payload: CreateSellOrderType = [dataItemInfo.nft_address, dataItemInfo.id, value.price, value.quantity, date, ORDER_TYPE.SELL];
        if (dataItemInfo.is_Approved) {
            const message = dataItemInfo.is_manual ? "Tạo lệnh bán thành công" : "Tạo lệnh bán thành công";
            const description = `${formatNumberPayment(value.quantity)} cổ phiếu mã ${dataItemInfo.stocks_code} với giá ${formatNumberPayment(value.price)} ${PAYMENT}`;
            handleCreateSellOrder(payload, message, description);
        } else {
            const { status } = await handleApprovalNft(contractNft, [dataItemInfo.contract_marketplace, true]);
            if (status === 200) {
                setDataItemInfo((pre) => ({ ...pre, is_Approved: true }));
            }
        }
    };

    React.useEffect(() => {
        if (storeAsset.data.length) {
            if (!dataItemInfo) {
                const filterNotPaused = storeAsset.data.filter((item) => !item.paused);
                if (filterNotPaused.length) {
                    const firstItem = filterNotPaused[0];
                    form.setFieldsValue({
                        share: firstItem.stocks_code,
                    })
                    setDataItemInfo(firstItem);
                }
            }
        }
    }, [dataItemInfo, form, storeAsset.data]);

    if (storeAsset.loading) {
        return (
            <div className="flex items-center justify-center min-h-[20vh]">
                <Spin />
            </div>
        )
    }

    if (!dataItemInfo || !storeAsset.data.length) return <NoData />;
    const disabledHours = () => {
        const hours = [];
        const currentHour = dayjs().hour();

        for (let i = currentHour - 1; i >= 0; i--) {
            hours.push(i);
        }

        return hours;
    };

    const disabledMinutes = (selectedHour) => {
        const minutes = [];
        const currentMinute = dayjs().minute();
        if (selectedHour === dayjs().hour()) {
            for (let i = currentMinute; i >= 0; i--) {
                minutes.push(i);
            }
        }
        return minutes;
    };
    return (
        <WrapperScroll className="max-h-[80vh]">
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
                scrollToFirstError
            >
                <Form.Item
                    label="Chọn mã cổ phiếu"
                    name="share"
                >
                    <Select
                        onChange={handleOnChangeSelect}
                        placeholder="Mã cổ phiếu!"
                        optionLabelProp="label"
                        className="w-full"
                        size="large"
                        showSearch
                        disabled={loadingTx}
                        notFoundContent={<NoData />}
                    >
                        {
                            storeAsset.data.map((item: InfoAssetType) => (
                                <Select.Option
                                    value={item.stocks_code}
                                    label={
                                        <Tooltip title={item.company?.length > 50 && item.company}>
                                            <div className="flex items-center gap-2 truncate">
                                                <p className="text-[#23262F] md:text-sm text-xs font-bold">{item.stocks_code}</p>
                                                <p className="text-[#777E90] md:text-sm text-xs font-bold truncate">{item.company}</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Tooltip title={item.company?.length > 50 && item.company}>
                                        <div className="flex gap-2 truncate">
                                            <p className="text-[#23262F] md:text-sm text-xs">{item.stocks_code}</p>
                                            <p className="text-[#23262F] md:text-sm text-xs truncate">{item.company}</p>
                                        </div>
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <WrapperItemInfo>
                        <ItemInfo title="Số lượng" value={dataItemInfo.quantity} uti="Cổ phiếu" />
                        <ItemInfo title="Tổng giá trị" value={dataItemInfo.total_value} uti={PAYMENT} />
                        <ItemInfo title="Số dư khả dụng" value={storeAccount.data.balance} uti={PAYMENT} />
                        <div className="text-right">
                            <EquivalentPayment value={storeAccount.data.balance} />
                        </div>
                    </WrapperItemInfo>
                </Form.Item>
                <Form.Item
                    label="Đơn giá"
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập đơn giá"
                        }
                    ]}
                >
                    <InputNumber
                        value={price}
                        bordered={false}
                        min={1}
                        disabled={dataItemInfo.paused || loadingTx}
                        placeholder="0"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => setPrice(e)}
                        addonAfter={
                            <p className="text-[#B1B5C3] font-medium text-xs uppercase mr-3">{PAYMENT}</p>
                        }
                        size="large"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    label="Số lượng"
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập số lượng",
                        },
                    ]}
                >
                    <InputNumber
                        min={1}
                        disabled={dataItemInfo.paused || loadingTx}
                        value={quantity}
                        bordered={false}
                        placeholder={`${formatNumberPayment(dataItemInfo.quantity)}`}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => setQuantity(e)}
                        max={dataItemInfo.quantity}
                        addonAfter={
                            <p
                                aria-hidden
                                onClick={handleMaxQuantity}
                                className="text-[#B1B5C3] font-medium cursor-pointer text-xs uppercase mr-2 hover:text-slate-700"
                            >Max</p>
                        }
                        size="large"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    label="Ngày hết hạn"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng đặt ngày hết hạn"
                        },
                    ]}
                >
                    <DatePicker
                        inputReadOnly
                        popupClassName="ant-remove-ok"
                        showNow={false}
                        showToday={false}
                        disabled={dataItemInfo.paused || loadingTx}
                        size="large"
                        showTime={{ format: 'HH:mm', }}
                        className="w-full"
                        allowClear={false}
                        placeholder={dayjs().format("DD/MM/YYYY HH:mm")}
                        format="DD/MM/YYYY HH:mm"
                        disabledDate={(current) => {
                            const customDate = dayjs().format("DD/MM/YYYY HH:mm");
                            return current && current < dayjs(customDate, "DD/MM/YYYY HH:mm");
                        }}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        onSelect={e => { form.setFieldsValue({ date: e }) }}
                        suffixIcon={<CalendarV2Icon />}
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => {
                        return (
                            <div>
                                <div className="flex items-center justify-between">
                                    <TitleTableMobile title="Số tiền thu về" />
                                    <div className="flex items-center gap-2">
                                        <ValueTableMobile value={formatNumberPayment(totalPrice)} className="text-base font-semibold" />
                                        <ValueTableMobile value={PAYMENT} className="text-base font-medium" color="text-[#B1B5C3] " />
                                    </div>
                                </div>
                                <div className="text-right">
                                    <EquivalentPayment value={totalPrice} />
                                </div>
                                <div className="grid grid-cols-2 md:gap-8 gap-2 mt-4">
                                    <Button
                                        onClick={handleCloseModal}
                                        style={{ border: "2px solid #E6E8EC" }}
                                        className="bg-white text-[#23262F]"
                                        disabled={loadingTx}
                                    >
                                        Hủy
                                    </Button>
                                    <Button
                                        loading={loadingTx}
                                        disabled={
                                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            || loadingTx || dataItemInfo.paused
                                            || !form.isFieldsTouched(false)
                                        }
                                    >
                                        {dataItemInfo.is_Approved ? "Tạo lệnh bán" : "Chấp thuận"}
                                    </Button>
                                </div>
                                {dataItemInfo.paused && <p className="text-[#EF1616] font-bold text-sm mt-4 text-center">Mã cổ phiếu đang tạm ngừng giao dịch</p>}
                            </div>
                        )
                    }}
                </Form.Item>
            </Form>
            <Note />
        </WrapperScroll>
    )
};
