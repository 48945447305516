import BigNumber from "bignumber.js";

import multicall from "utils/multicall";
import { ERC20_ABI } from "config/abi/erc20";
import nftShareholderBook from "config/constants/shareholder-book";
import { getApprover } from "state/state-info-asset";

import { renderTokenPayment } from "hooks";

export const getDataAccount = async (account: string, marketplaceAddress: string, chainId: number) => {
    try {
        const tokenPayment = renderTokenPayment(chainId);
        const calls = [
            {
                address: tokenPayment.address,
                name: 'allowance',
                params: [account, marketplaceAddress]
            },
            {
                address: tokenPayment.address,
                name: 'balanceOf',
                params: [account]
            }
        ];
        const [resultAllowance, resultBalanceOf] = await multicall(ERC20_ABI, calls, chainId);
        return {
            allowance: new BigNumber(resultAllowance[0].toString()).dividedBy(new BigNumber(10).pow(tokenPayment.decimals)).toNumber(),
            balance: new BigNumber(resultBalanceOf[0].toString()).dividedBy(new BigNumber(10).pow(tokenPayment.decimals)).toNumber(),
            account
        }
    } catch (error) {
        console.log('error', error)
        return {
            allowance: 0,
            balance: 0,
            account: null,
        }
    }
};

export const getApproverAccount = async (account: string, chainId: number) => {
    try {
        const resultNft = await nftShareholderBook(chainId);
        const result = await Promise.all(resultNft.map(async (nft: string) => {
            const { approver } = await getApprover(nft, chainId);
            return approver
        }));
        const checkApprover = result.find((i: string) => i?.toLowerCase() === account?.toLowerCase());
        return !!checkApprover;

    } catch (error) {
        return false;
    }
}
