import { storeAccount as reducerStoreAccount, useAppSelector } from 'state';
import { InitialAccountType } from 'type';
import useActiveWeb3React from './useActiveWeb3React';

export const useAccount = () => {
    const { account, chainId } = useActiveWeb3React();
    const storeAccount: InitialAccountType = useAppSelector(reducerStoreAccount);
    return {
        storeAccount,
        account,
        chainId
    }
}
