import * as React from 'react';

import { renderMarketplaceAddress, useInfoAsset } from 'hooks';
import { useAppDispatch } from 'state';
import useActiveWeb3React from 'hooks/useActiveWeb3React';

import { getDataInfoAsset } from './fetch-data';
import { actionDataCreateBuyOrder, actionGetDataInfoAsset, actionLoadingDataInfoAsset } from './actions';

export * from "./actions";
export * from "./reducer";
export * from "./fetch-data";

export const useStateInfoAsset = () => {
    const { storeAsset } = useInfoAsset();
    const { chainId, account } = useActiveWeb3React();
    const dispatch = useAppDispatch();
    const marketplaceAddress = renderMarketplaceAddress(chainId);

    const handleFetchData = React.useCallback(async () => {
        const result = await getDataInfoAsset(chainId, account, marketplaceAddress);
        dispatch(actionGetDataInfoAsset(result.filter(item => item.quantity)));
        dispatch(actionDataCreateBuyOrder(result));
        dispatch(actionLoadingDataInfoAsset(false));
    }, [chainId, account, dispatch, marketplaceAddress])

    React.useEffect(() => {
        (async () => {
            if (!storeAsset.data.length) {
                dispatch(actionLoadingDataInfoAsset(true));
            }
            await handleFetchData();
        })();
    }, [dispatch, handleFetchData, storeAsset.data.length]);

    React.useEffect(() => {
        (() => {
            if (storeAsset.transactionHash && account) {
                handleFetchData();
            }
        })()
    }, [dispatch, handleFetchData, storeAsset.transactionHash, account]);
}
