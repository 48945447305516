import clsx from "clsx";

interface LoadingTransactionDesktopType {
    item?: number;
    className?: string;
}

export const LoadingTransactionDesktop = ({ item = 5, className }: LoadingTransactionDesktopType) => {
    return (
        <div className={clsx("animate-pulse flex space-x-4 flex-col gap-5", className)}>
            {
                [...Array(item)].map((_a, i) => (
                    <div
                        className="grid w-full grid-cols-6 gap-4 p-4 rounded-md"
                        key={`${i.toString()}`}
                        style={{
                            margin: 0,
                            border: "1px solid rgba(207, 219, 213, 0.60)"
                        }}
                    >
                        {
                            [...Array(6)].map((_, index) => (
                                <div className="flex-1 space-y-6" key={`${index.toString()}`}>
                                    <div className="h-6 bg-slate-300 rounded" />
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}
