
import { useTranslation } from "@pancakeswap/localization";
import { CONTENT_ERROR } from "config";
import { useNotification } from "./use-notification";

export const useRejected = () => {
    const { notification } = useNotification();
    const { t } = useTranslation()

    const handleRejected = (error) => {
        const stringifyError = JSON.stringify(error);
        const parseError = JSON.parse(stringifyError);
        console.log('parseError', parseError)

        if (parseError?.code === "ACTION_REJECTED") notification({
            message: t(CONTENT_ERROR),
            type: "error",
        });
        else if (parseError?.code === "UNPREDICTABLE_GAS_LIMIT") notification({
            message: "Đã xảy ra lỗi. Xác nhận giao dịch và đảm bảo bạn có đủ tiền trong ví để xử lý giao dịch!",
            type: "error",
        });
        else notification({
            message: t("Đã xảy ra lỗi. Xác nhận giao dịch và đảm bảo bạn có đủ tiền trong ví để xử lý giao dịch!"),
            type: "error",
        })
    };
    return { handleRejected }
};
