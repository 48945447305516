
export const Note = () => {
    return (
        <div className="text-center">
            <p className="text-[#ED930B] font-bold text-sm">Vui lòng chờ xác nhận!</p>
            <p className="text-[#ED930B] font-normal text-sm sm:w-full w-[250px] text-center m-auto">Giao dịch của bạn sẽ được xác nhận trong vòng 8 giờ làm việc</p>
            <p className="text-[#ED930B] font-normal text-sm">(không bao gồm các ngày lễ và cuối tuần).</p>
        </div>
    )
}
