import clsx from 'clsx';
import { TitleTableMobile, ValueTableMobile } from 'components'
import { formatNumberPayment } from 'config'

interface ItemInfoType {
    title: string;
    value?: number | string;
    uti?: string;
}

export const ItemInfo = ({ title, value, uti }: ItemInfoType) => {
    return (
        <div className="flex items-center justify-between">
            <TitleTableMobile title={title} className="md:text-sm text-xs font-bold" />
            <div className="flex items-center">
                {value && <ValueTableMobile value={typeof value === "number" ? formatNumberPayment(value) : value} className="text-base font-semibold" />}
                <ValueTableMobile value={uti} className="text-base font-bold ml-1" color='text-[#B1B5C3]' />
            </div>
        </div>
    )
};

export const WrapperItemInfo = ({ children, className }: { children: React.ReactNode, className?: string }) => {
    return (
        <div className={clsx("bg-[#F4F5F6] rounded-md p-3 flex flex-col gap-3", className)}>
            {children}
        </div>
    )
};
