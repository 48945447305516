import { Typography } from "antd";
import clsx from "clsx";
import { handleWallet } from "config";
import { FaRegCopy } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";

export const WrapperCopy = ({ value, isShowValue = true, className }: {
    value: string,
    isShowValue?: boolean,
    className?: string
}) => {
    return (
        <Typography.Text
            className="flex items-center"
            copyable={{
                icon: [<FaRegCopy className={clsx("text-[#23262F] md:text-lg text-sm", className)} />, <FcCheckmark />],
                text: value
            }}
        >
            {isShowValue && <p
                className="text-[#23262F] text-sm"
                style={{ marginBottom: "0" }}
            >{handleWallet(value)}</p>}
        </Typography.Text>
    )
}
