import React from 'react';
import { Modal, Tooltip } from "antd";
import { VscClose } from "react-icons/vsc"

import { useModal } from 'hooks/use-modal';
import { ModalTypeEnum } from 'config';
import {
    ModalCreateSellOrder,
    ModalCancelOrderBook,
    ModalBuyShares,
    ModalCreateBuyOrder,
    ModalSellShares,
    ModalListShareholders,
    ModalTransfer
} from './components';

const checkModal = (modalType: string) => {
    switch (modalType) {
        case ModalTypeEnum.MODAL_CREATE_SELL_ORDER:
            return ModalCreateSellOrder;
        case ModalTypeEnum.MODAL_CREATE_BUY_ORDER:
            return ModalCreateBuyOrder;
        case ModalTypeEnum.MODAL_CANCEL_ORDER_BOOK:
            return ModalCancelOrderBook;
        case ModalTypeEnum.MODAL_BUY_SHARES:
            return ModalBuyShares;
        case ModalTypeEnum.MODAL_SELL_SHARES:
            return ModalSellShares;
        case ModalTypeEnum.MODAL_TRANSFER:
            return ModalTransfer;
        case ModalTypeEnum.MODAL_LIST_SHAREHOLDERS:
            return ModalListShareholders;
        default:
            return null;
    }
};

export const ContainerModal = () => {
    const { resultModal, handleCloseModal } = useModal();
    const { data, modal, title, type, isPauseClose } = resultModal;

    const WrapperModal = React.useMemo(() => checkModal(type), [type]);

    React.useEffect(() => {
        if (WrapperModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
    }, [WrapperModal]);

    if (!WrapperModal) return null;
    return (
        <Modal
            width={500}
            open
            centered
            title={
                <div className="flex justify-between items-center gap-2">
                    <h6 className="font-bold md:text-[26px] text-xl text-[#23262F]">{title}</h6>
                    <Tooltip placement="top" title={isPauseClose ? "Chờ giao dịch" : "Esc đóng"} >
                        <div
                            aria-hidden
                            onClick={handleCloseModal}
                            className="shadow-md cursor-pointer md:w-[40px] w-[28px] md:h-[40px] h-[28px] flex items-center justify-center rounded-full"
                            style={{ border: "2px solid #E6E8EC" }}>
                            <VscClose className='text-lg md:text-base' />
                        </div>
                    </Tooltip>
                </div>
            }
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
            footer={null}
            closeIcon={false}
            {...modal}
        >
            <WrapperModal {...data} />
        </Modal>
    )
};
