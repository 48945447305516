import { Pagination } from 'antd';
import React from 'react';

interface PaginationType {
    data: Array<any>;
    pageSize: number;
}
export const usePagination = ({
    data = [],
    pageSize = 3,
}: PaginationType) => {
    const totalPagination = data?.length || 0;
    const [itemOffset, setItemOffset] = React.useState(0);
    const dataPagination = [...data].splice(itemOffset, pageSize);
    const [pageCurrent, setPageCurrent] = React.useState(1);
    return {
        dataPagination,
        pagination: () => <Pagination
            defaultPageSize={pageSize}
            current={pageCurrent}
            total={totalPagination}
            onChange={(page, size) => {
                setItemOffset((page - 1) * size);
                setPageCurrent(page);
            }}
        />
    }
};
