import { ChainId } from "@pancakeswap/sdk";
import { PAYMENT, PAYMENT_TOKEN } from "config";
import contracts from "config/constants/contracts"
import { Address } from 'config/constants/types';
import { bscTokens, bscTestnetTokens, onusMainnetTokens } from "@pancakeswap/tokens";

export const getAddressShareholderBook = (address: Address, chainId: number): string => {
    return address[chainId] ? address[chainId] : address[ChainId.BSC]
};

export const renderTokenPayment = (chainId: number) => {
    if (chainId === ChainId.BSC_TESTNET) {
        if (PAYMENT_TOKEN.VND === PAYMENT) return bscTestnetTokens.vndc
        if (PAYMENT_TOKEN.USD === PAYMENT) return bscTestnetTokens.usdt
    }
    if (chainId === ChainId.ONUS) {
        if (PAYMENT_TOKEN.VND === PAYMENT) return onusMainnetTokens.vndc
        if (PAYMENT_TOKEN.USD === PAYMENT) return onusMainnetTokens.usdt
    }
    if (chainId === ChainId.BSC) {
        if (PAYMENT_TOKEN.VND === PAYMENT) return bscTokens.vndc
        if (PAYMENT_TOKEN.USD === PAYMENT) return bscTokens.usdt
    }
    return onusMainnetTokens.vndc
};

export const renderMarketplaceAddress = (chainId: number) => {
    return getAddressShareholderBook(contracts.shareholderBook, chainId)
};

