export const getPoolProposalConfig = async (chainId: number) => {
    try {
        return (await import(`/${chainId}.ts`)).default.filter(
            (f: string) => f !== null,
        ) as string[]
    } catch (error) {
        console.log('Cannot get ccq config', error, chainId)
        return []
    }
};

async function nftShareholderBook(chainId: number) {
    const result = await getPoolProposalConfig(chainId);
    return result;
}
export default nftShareholderBook
