import { Button } from '@pancakeswap/uikit';
import clsx from 'clsx';

interface CustomButtonType {
    className?: string;
    title: string;
    onClick?: () => void;
    type?: string,
    disabled?: boolean;
    isLoading?: boolean;
    endIcon?: React.ReactNode
}

export const CustomButton = ({
    className,
    title,
    onClick,
    type,
    disabled,
    isLoading,
    endIcon,
    ...props
}: CustomButtonType) => {
    return (
        <Button
            endIcon={endIcon}
            isLoading={isLoading}
            disabled={disabled}
            {...props}
            onClick={onClick}
            className={clsx(
                "rounded h-auto px-4 py-2 text-sm",
                className,
            )}
        >{title}</Button>
    )
}
