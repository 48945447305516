import BigNumber from "bignumber.js";

import nftShareholderBook from "config/constants/shareholder-book";
import multicall from "utils/multicall";
import nftShareholderBookAbi from "config/abi/nftShareholderBookAbi.json";
import { InfoAssetType } from "type";
import { DEFAULT_ID } from "config";
import { getIsManual, getPaused } from "state/state-info-order/fetch-data";

export const getDataInfoAsset = async (chainId: number, account: string, marketplaceAddress: string): Promise<InfoAssetType[]> => {
    try {
        if (account) {
            const resultNft = await nftShareholderBook(chainId);
            const result = await Promise.all(resultNft.map(async (nft: string) => {
                const stocks_code = await getStockSymbol(nft, chainId);
                const price = await getIssuePrice(nft, chainId);
                const company = await getCompanyName(nft, chainId);
                const quantity = await getBalanceOf(nft, account, chainId);
                const is_Approved = await isApprovedForAll(nft, account, marketplaceAddress, chainId);
                const paused = await getPaused(nft, chainId);
                const isManual = await getIsManual(nft, chainId);

                return {
                    id: DEFAULT_ID, // default 0
                    nft_address: nft,
                    account,
                    contract_marketplace: marketplaceAddress,
                    ...isManual,
                    ...stocks_code,
                    ...price,
                    ...quantity,
                    ...company,
                    ...is_Approved,
                    ...paused,
                    total_value: new BigNumber(quantity.quantity).multipliedBy(price.price).toNumber(),
                }
            }));
            return result;
        }
        return []
    } catch (error) {
        console.log('error getDataInfoAsset:', error);
        return [];
    }
};

export const getStockSymbol = async (address: string, chainId: number): Promise<{ stocks_code: string }> => {
    try {
        const call = [{ address, name: "stockSymbol", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            stocks_code: result[0]
        }
    } catch (error) {
        console.log('error stockSymbol:', error);
        return {
            stocks_code: null
        }
    }
};

export const isApprovedForAll = async (address: string, account: string, contractMarketplace: string, chainId: number): Promise<{ is_Approved: boolean }> => {
    try {
        const call = [{ address, name: "isApprovedForAll", params: [account, contractMarketplace] }];
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            is_Approved: result[0]
        }
    } catch (error) {
        console.log('isApprovedForAll:', error);
        return {
            is_Approved: false
        }
    }
};

export const getCompanyName = async (address: string, chainId: number): Promise<{ company: string }> => {
    try {
        const call = [{ address, name: "companyName", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            company: result[0]
        }
    } catch (error) {
        console.log('error companyName:', error);
        return {
            company: null
        }
    }
};

export const getIssuePrice = async (address: string, chainId: number): Promise<{ price: number }> => {
    try {
        const call = [{ address, name: "issuePrice", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            price: new BigNumber(result).toNumber(),
        }
    } catch (error) {
        console.log('issuePrice:', error);
        return {
            price: 0
        }
    }
};

export const getBalanceOf = async (address: string, account: string, chainId: number): Promise<{ quantity: number }> => {
    try {
        const call = [{ address, name: "balanceOf", params: [account, 0] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            quantity: new BigNumber(result).toNumber(),
        }
    } catch (error) {
        console.log('balanceOf:', error);
        return {
            quantity: 0
        }
    }
};

export const getApprover = async (address: string, chainId: number): Promise<{ approver: string }> => {
    try {
        const call = [{ address, name: "approver", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            approver: result[0]
        }
    } catch (error) {
        console.log('error approver:', error);
        return {
            approver: null
        }
    }
};
