import clsx from "clsx";

interface LoadingTransactionMobileType {
    item?: number;
    className?: string;
}

export const LoadingTransactionMobile = ({ item = 5, className }: LoadingTransactionMobileType) => {
    return (
        <div className={clsx("animate-pulse mt-6 grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 space-x-4", className)}>
            {
                [...Array(item)].map((_a, i) => (
                    <div
                        className="grid w-full grid-cols-1 gap-4 p-4 rounded-md"
                        key={`${i.toString()}`}
                        style={{
                            margin: 0,
                            border: "1px solid rgba(207, 219, 213, 0.60)"
                        }}
                    >
                        <div className="flex-1 space-y-6">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="flex flex-col gap-2">
                                    <div className="h-4 bg-slate-300 rounded" />
                                    <div className="h-4 bg-slate-300 rounded" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="h-4 bg-slate-300 rounded" />
                                    <div className="h-4 bg-slate-300 rounded" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="h-4 bg-slate-300 rounded" />
                                    <div className="h-4 bg-slate-300 rounded" />
                                </div>
                            </div>
                            <div className="flex w-full justify-between">
                                <div className="h-4 w-14 bg-slate-300 rounded" />
                                <div className="h-4 w-14 bg-slate-300 rounded" />
                            </div>
                            <div className="flex w-full justify-between">
                                <div className="h-4 w-16 bg-slate-300 rounded" />
                                <div className="h-4 w-20 bg-slate-300 rounded" />
                            </div>
                            <div className="h-8 w-full bg-slate-300 rounded" />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
