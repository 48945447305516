import { Table } from 'antd';
import BigNumber from 'bignumber.js';

import { NoData, TitleTableDesktop, ValueTableDesktop, WrapperCopy } from 'components';
import { formatNumberPayment } from 'config';

interface Props {
    issuePrice: string,
    listHolders: {
        shareholderAddress: string,
        amount: string
    }[]
}
export const TableDesktop:React.FC<Props> = ({
    issuePrice,
    listHolders
}) => {
    const dataShareholders = listHolders?.map((dt) => {
        return {
            holderAddress: dt?.shareholderAddress,
            amount: dt?.amount,
            totalValueOfStock: new BigNumber(dt?.amount).multipliedBy(issuePrice).toString()
        }
    })
    const columns = [
        {
            title: <TitleTableDesktop title="STT" classNameWrapper="justify-center" />,
            dataIndex: "wallet",
            key: "wallet",
            width: 120,
            render: (_, record, index) => {
                return (
                    <ValueTableDesktop value={index + 1} classNameWrapper="justify-center" />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Địa chỉ ví" />,
            dataIndex: "holderAddress",
            key: "holderAddress",
            width: 400,
            render: (text) => {
                return (
                    <div className="flex justify-between items-center">
                        <ValueTableDesktop value={text} />
                        <WrapperCopy value={text} isShowValue={false} />
                    </div>
                )
            }
        },
        {
            title: <TitleTableDesktop title="Số lượng cổ phiếu" classNameWrapper="justify-end" />,
            dataIndex: "amount",
            key: "amount",
            render: (text) => {
                return (
                    <ValueTableDesktop value={formatNumberPayment(text)} classNameWrapper="justify-end" />
                )
            }
        },
        {
            title: <TitleTableDesktop title="Tổng giá trị cổ phiếu" classNameWrapper="justify-end" />,
            dataIndex: "totalValueOfStock",
            key: "totalValueOfStock",
            render: (text) => {
                return (
                    <ValueTableDesktop value={formatNumberPayment(text)} classNameWrapper="justify-end" />
                )
            }
        },
    ];

    return (
        <Table
            scroll={{
                y: 250,
            }}
            dataSource={dataShareholders}
            columns={columns}
            pagination={false}
            locale={{
                emptyText: <NoData />,
            }}
        />
    )
}
