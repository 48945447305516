import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg viewBox="0 0 28 28" {...props} style={{ fill: "none" }}>
            <path d="M14.0007 25.6666C20.4173 25.6666 25.6673 20.4166 25.6673 13.9999C25.6673 7.58325 20.4173 2.33325 14.0007 2.33325C7.58398 2.33325 2.33398 7.58325 2.33398 13.9999C2.33398 20.4166 7.58398 25.6666 14.0007 25.6666Z" fill="#49DB04" stroke="#49DB04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.04102 13.9999L12.3427 17.3016L18.9577 10.6982" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );
};

export default Icon;
