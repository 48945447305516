import * as React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';

import { BiSolidChevronDown } from "react-icons/bi";

export const WrapperCollapse = ({ children }: { children: React.ReactNode }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <CsCollapse
            onChange={(value) => {
                setOpen(!!value.length)
            }}
            ghost
            expandIcon={({ isActive }) => <BiSolidChevronDown color="#355DFF" style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
        >
            <Collapse.Panel header={<p className="text-[#355DFF] text-[13px] font-semibold">{open ? "Thu gọn" : "Chi tiết"}</p>} key="1">
                {children}
            </Collapse.Panel>
        </CsCollapse>
    )
};

const CsCollapse = styled(Collapse)`
    .ant-collapse-item{
        .ant-collapse-content-box{
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .ant-collapse-header{
            padding: 0;
            display: flex;
            align-items: center !important;
            flex-direction: row-reverse !important;
            width: 70px !important;
            margin-left: auto;
            .ant-collapse-expand-icon{
                margin: 0 !important;
                padding: 0 !important;
            }
    }
}
`
