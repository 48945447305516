import { ParamsGetApiOrder } from "type";
import axiosClient from "./axios-client";

export const apiOrder = {
    getApiOrder: (params: ParamsGetApiOrder) => {
        const url = "v1/order";
        return axiosClient.get(url, { params });
    },
    getApiHistory: (params: {wallet:string}) => {
        const url = `/v1/trade-history/export/${params?.wallet}`;
        return axiosClient.get(url);
    }
};

