export const IconCalendar = (props) => {
    return (
        <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5.5H14" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13 2.5H1C0.448 2.5 0 2.948 0 3.5V14.5C0 15.052 0.448 15.5 1 15.5H13C13.552 15.5 14 15.052 14 14.5V3.5C14 2.948 13.552 2.5 13 2.5Z" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 0.5V2.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 0.5V2.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 0.5V2.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 7.5V13.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 7.5V13.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 10.5H12" stroke="#222222" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};
