import { Button, useMatchBreakpoints } from '@pancakeswap/uikit';
import { Input, Tooltip } from 'antd';
import { Select, TitleSection, WrapperScroll, WrapperSelect } from 'components';
import { OptionProps } from 'components/Select/Select';
import { formatNumberPayment } from 'config';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { IconDownload, IconStatusUp } from 'icons';
import { useMemo, useState } from 'react';
import { CSVLink } from "react-csv";
import { CiSearch } from "react-icons/ci";
import { GetDataChairperson, UseDataStocks } from 'state/state-chairperson/hook';
import styled from 'styled-components';
import { renderDate } from 'utils/renderDate';
import { TableDesktop } from './table-desktop';
import { TableMobile } from './table-mobile';

export const ModalListShareholders = () => {
    const data = GetDataChairperson()
    const { chainId } = useActiveWeb3React()
    const { infoStocks, stock } = data
    const [search, setSearch] = useState("")
    const options = infoStocks?.map((dt) => {
        return {
            label: dt?.symbol,
            value: dt?.contractAddress,
        }
    })
    const { isDesktop } = useMatchBreakpoints()
    const [stocksAddress, setStocksAddress] = useState(infoStocks ? infoStocks[0]?.contractAddress : "")
    const handleSelectStocksSymbol = (option: OptionProps): void => {
        setStocksAddress(option?.value)
    }
    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        const re = /[0-9a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/
        if (event.target.value === '' || re.test(event.target.value)) {
            const values = event.target.value
            const replace = values.replace(/[.><,'":;/*+?^${}()|[\]\\!@#%&_~]/g, "");
            setSearch(replace)
        }
    }
    UseDataStocks(chainId, stocksAddress)

    const filterData = useMemo(() => {
        if (search?.length > 0) {
            return [...stock?.shareHolders?.filter((dt) => dt?.shareholderAddress.toLowerCase().includes(search?.toLowerCase()))]
        }
        return stock?.shareHolders
    }, [search, stock?.shareHolders])

    const headers = [
        { label: "STT", key: "index" },
        { label: "Địa chỉ ví", key: "holderAddress" },
        { label: "Số lượng cổ phiếu", key: "amount" },
        { label: "Tổng giá trị cổ phiếu", key: "totalValueOfStock" },
    ]
    const dataCsv = useMemo(() => {
        return filterData?.map((dt, index) => {
            return {
                index: index + 1,
                holderAddress: dt?.shareholderAddress,
                amount: formatNumberPayment(Number(dt?.amount)),
                totalValueOfStock: formatNumberPayment(Number(dt?.amount) * Number(stock?.issuePrice)),
            }
        })

    }, [filterData, stock?.issuePrice])

    return (
        <WrapperScroll className="max-h-96 lg:max-h-full">
            <div className="flex sm:flex-row flex-col justify-between sm:gap-6 gap-4">
                <div className="flex gap-6 basis-3/5">
                    <WrapperSelect title="Mã cổ phiếu">
                        <Select
                            className="sm:rounded-xl rounded-md sm:h-[48px] h-10 text-sm"
                            options={options?.length > 0 ? options : []}
                            defaultOptionIndex={0}
                            startIcon={<IconStatusUp />}
                            onOptionChange={handleSelectStocksSymbol}
                        />
                    </WrapperSelect>
                </div>
                <div className="sm:basis-2/5 basis-full w-full flex self-end justify-end">
                    <div
                        className="flex content-end sm:max-w-[300px] w-full sm:rounded-xl rounded-lg"
                        style={{ border: "2px solid #E6E8EC" }}
                    >
                        <Input
                            bordered={false}
                            value={search}
                            placeholder="Tìm kiếm"
                            className="placeholder:text-xs placeholder:text-[#777E90] sm:p-3 p-2"
                            suffix={<CiSearch className="text-[#777E90] text-lg font-semibold cursor-pointer" />}
                            onChange={(evt) => handleChangeSearchQuery(evt)}
                        />
                    </div>
                </div>
            </div>
            <div className="lg:my-8 my-5">
                <div className="flex justify-between items-center">
                    <TitleSection className="lg:text-3xl sm:w-auto w-full text-left text-lg" title={`Số lượng cổ đông: ${stock?.shareHolders.length}`} />
                    {isDesktop &&
                        <>
                            {dataCsv?.length > 0 ?
                                <CSVLink
                                    data={dataCsv}
                                    headers={headers}
                                    filename={`${stock?.symbol?.toUpperCase()}_${renderDate()}.csv`}
                                    className="btn btn-primary"
                                    target="_blank"
                                >
                                    <Button
                                        width={["100%", , "auto"]}
                                        endIcon={<IconDownload />}
                                    >
                                        Export
                                    </Button>
                                </CSVLink>
                                :
                                <Button
                                    width={["100%", , "auto"]}
                                    endIcon={<IconDownload />}
                                    disabled
                                >
                                    Export
                                </Button>
                            }
                        </>

                    }
                </div>
            </div>
            <div className="lg:my-8 my-5 lg:bg-white bg-[#F4F5F6] lg:p-0 p-3 rounded-lg grid lg:grid-cols-3 grid-cols-1 gap-4">
                <div className="flex justify-between items-center">
                    <h4 className="text-[#23262F] font-bold lg:text-lg text-sm">Mã cổ phiếu:</h4>
                    <p className="text-[#B1B5C3] lg:text-lg text-sm font-bold">{stock?.symbol}</p>
                </div>
                <div className="flex justify-between items-center">
                    <h4 className="text-[#23262F] font-bold lg:text-lg text-sm whitespace-nowrap">Tên công ty:</h4>
                    <Tooltip placement="top" title={stock?.companyName} trigger={["hover"]}>
                        <CsTextNameCompany className="text-[#B1B5C3] lg:text-lg text-sm font-bold text-name-company">
                            {stock?.companyName?.length > 20 ? `${stock?.companyName?.slice(0, 20)}...` : stock?.companyName}
                        </CsTextNameCompany>
                    </Tooltip>
                </div>
                <div className="flex justify-between items-center">
                    <h4 className="text-[#23262F] font-bold lg:text-lg text-sm">Mệnh giá(VND):</h4>
                    <p className="text-[#B1B5C3] lg:text-lg text-sm font-bold">{formatNumberPayment(Number(stock?.issuePrice))}</p>
                </div>
            </div>
            <div className="lg:block hidden">
                <TableDesktop issuePrice={stock?.issuePrice} listHolders={filterData} />
            </div>
            <div className="lg:hidden block">
                <TableMobile issuePrice={stock?.issuePrice} listHolders={filterData} />
            </div>
        </WrapperScroll>
    )
};

const CsTextNameCompany = styled.p`
    white-space: nowrap;
    text-align: right;
`

