import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AccountType, InitialAccountType } from 'type';
import { accountGetDataAccount, actionTransactionHashAccount, accountLoadingGetDataAccount, actionGetApproverAccount } from './actions';


const initialState: InitialAccountType = {
    loading: true,
    data: {
        allowance: 0,
        balance: 0,
        account: null,
    },
    transactionHash: null,
    isApprover: false,
};


export const stateAccount = createReducer(initialState, (builder) =>
    builder
        .addCase(accountGetDataAccount, (state: InitialAccountType, action: PayloadAction<AccountType>) => {
            state.data = action.payload
        })
        .addCase(actionTransactionHashAccount, (state: InitialAccountType, action: PayloadAction<string>) => {
            state.transactionHash = action.payload;
        })
        .addCase(accountLoadingGetDataAccount, (state: InitialAccountType, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        })
        .addCase(actionGetApproverAccount, (state: InitialAccountType, action: PayloadAction<boolean>) => {
            state.isApprover = action.payload;
        })
)
