import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'
import moment from 'moment'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0.0001735
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.0001735)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://board.runtogether.net'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = '2500000'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5
export const ITEMSPERPAGE = 8
export const WALLET_NULL = "0x0000000000000000000000000000000000000000";

export const MAX_AMOUNT_TRANSFER_NFT = 50

export const NFT_TYPE = {
    DIAMOND: 4,
    GOLD: 3,
    SILVER: 2,
    BRONZE: 1,
}

export const PAGE_SUPPORT_CHAIN = [ChainId.ONUS]
export const CONTENT_ERROR = "Bạn đã hủy giao dịch này!"

export const ModalTypeEnum = {
    NULL: "NULL",
    MODAL_CREATE_SELL_ORDER: "MODAL_CREATE_SELL_ORDER",
    MODAL_CREATE_BUY_ORDER: "MODAL_CREATE_BUY_ORDER",
    MODAL_CANCEL_ORDER_BOOK: "MODAL_CANCEL_ORDER_BOOK",
    MODAL_BUY_SHARES: "MODAL_BUY_SHARES",
    MODAL_SELL_SHARES: "MODAL_SELL_SHARES",
    MODAL_TRANSFER: "MODAL_TRANSFER",
    MODAL_LIST_SHAREHOLDERS: "MODAL_LIST_SHAREHOLDERS",
}

export const ORDER_TYPE = {
    BUY: 0, // tạo lệnh mua
    SELL: 1, // tạo lệnh bán
    TRANSFER: 2, // chuyển nhượng
    BUY_NOW: 3, // mua ngay
    SELL_NOW: 4, // bán ngay
    ALL: 5,
    REJECT: 400
};

export const RESPONSE_TYPE = {
    SUCCESS: 200,
    FAIL: 400
}

export const STATUS_TYPE = {
    PENDING: 0,
    CREATED: 1,
    COMPLETED: 2,
    REJECTED: 3,
    CANCELLED: 4,
    PAUSED: 5,
    ALL: 999
}

export const checkOrderType = (orderType: number) => {
    switch (orderType) {
        case ORDER_TYPE.BUY:
            return "Tạo lệnh mua";
        case ORDER_TYPE.SELL:
            return "Tạo lệnh bán";
        case ORDER_TYPE.TRANSFER:
            return "Chuyển nhượng";
        case ORDER_TYPE.SELL_NOW:
            return "Bán";
        case ORDER_TYPE.BUY_NOW:
            return "Mua";
        case ORDER_TYPE.REJECT:
            return "Hủy";
        default:
            return "ERROR";
    }
};

export interface DefaultDataOptionStocksCodeType {
    value: string;
    label: string;
    code: number;
}

export const defaultDataOptionStocksCode: DefaultDataOptionStocksCodeType[] = [
    { value: "All", label: "Tất cả", code: ORDER_TYPE.ALL },
    { value: checkOrderType(ORDER_TYPE.BUY), label: checkOrderType(ORDER_TYPE.BUY), code: ORDER_TYPE.BUY },
    { value: checkOrderType(ORDER_TYPE.SELL), label: checkOrderType(ORDER_TYPE.SELL), code: ORDER_TYPE.SELL },
    { value: checkOrderType(ORDER_TYPE.TRANSFER), label: checkOrderType(ORDER_TYPE.TRANSFER), code: ORDER_TYPE.TRANSFER },
    { value: checkOrderType(ORDER_TYPE.SELL_NOW), label: checkOrderType(ORDER_TYPE.SELL_NOW), code: ORDER_TYPE.SELL_NOW },
    { value: checkOrderType(ORDER_TYPE.BUY_NOW), label: checkOrderType(ORDER_TYPE.BUY_NOW), code: ORDER_TYPE.BUY_NOW }
];

export const checkDefaultStatus = (statusType: number) => {
    switch (statusType) {
        case STATUS_TYPE.PENDING:
            return "Chờ xác nhận";
        case STATUS_TYPE.CREATED:
            return "Đã xác nhận";
        case STATUS_TYPE.COMPLETED:
            return "Thành công";
        case STATUS_TYPE.REJECTED:
            return "Không xác nhận";
        case STATUS_TYPE.CANCELLED:
            return "Đã Hủy";
        case STATUS_TYPE.PAUSED:
            return "Tạm ngừng";
        default:
            return "ERROR";
    }
};

export const checkOrderColor = (orderType: number) => {
    switch (orderType) {
        case ORDER_TYPE.BUY:
            return "#EF1616";
        case ORDER_TYPE.TRANSFER:
            return "#ED930B";
        case ORDER_TYPE.BUY_NOW:
            return "#11DC2E";
        case ORDER_TYPE.SELL:
            return "#11DC2E";
        case ORDER_TYPE.SELL_NOW:
            return "#EF1616";
        default:
            return "#00ffef";
    }
};

export const checkStatusColor = (statusType: number) => {
    switch (statusType) {
        case STATUS_TYPE.PENDING:
            return "#F2B10A";
        case STATUS_TYPE.CREATED:
            return "#11DC2E";
        case STATUS_TYPE.COMPLETED:
            return "#11DC2E";
        case STATUS_TYPE.REJECTED:
            return "#EF1616";
        case STATUS_TYPE.CANCELLED:
            return "#EF1616";
        default:
            return "#F2B10A";
    }
};



export const dataAssetsTab = ["Sổ lệnh", "Tài sản", "Tra cứu"];
export const dataTransactionTab = ["Mua cổ phiếu", "Bán cổ phiếu"];

export const PAYMENT_TOKEN = {
    VND: "VND", // VNDC
    USD: "USD", // USDT
}

export const DEFAULT_ID = 0;
export const PAGE_SIZE_ORDER = 10;

export const PAYMENT = PAYMENT_TOKEN.VND

export const formatNumberPayment = (price: number, minimum = 0, maximum = 0) => {
    if (!price) return 0;
    if (PAYMENT === PAYMENT_TOKEN.USD) {
        return Number(new BigNumber(price).decimalPlaces(5, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 }).replace(/\.?0+$/, '')
    }
    return Number(new BigNumber(price).decimalPlaces(0, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: minimum, maximumFractionDigits: maximum })
}

export const formatDate = (time: number) => {
    if (!time) {
        return "ERROR"
    }
    return moment(time).format("DD/MM/YYYY HH:mm")
}

export const handleWallet = (address: string, sub = 4) => {
    if (!address) return null;
    return `${address.substring(0, sub)}...${address.substring(address.length - sub)}`;
}
