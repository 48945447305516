import React, { cloneElement, ElementType, isValidElement } from "react";
import { Spin } from 'antd';
import EXTERNAL_LINK_PROPS from "../../util/externalLinkProps";
import StyledButton from "./StyledButton";
import { ButtonProps, scales, variants } from "./types";

const Button = <E extends ElementType = "button">(props: ButtonProps<E>): JSX.Element => {
    const { startIcon, endIcon, external, className, isLoading, disabled, children, loading, ...rest } = props;
    const internalProps = external ? EXTERNAL_LINK_PROPS : {};
    const isDisabled = isLoading || disabled;
    const classNames = className ? [className] : [];

    if (isLoading) {
        classNames.push("pancake-button--loading");
    }

    if (isDisabled && !isLoading) {
        classNames.push("pancake-button--disabled");
    }

    return (
        <StyledButton
            $isLoading={isLoading}
            className={classNames.join(" ")}
            disabled={isDisabled}
            {...internalProps}
            {...rest}
        >
            <>

                {isValidElement(startIcon) &&
                    cloneElement(startIcon, {
                        // @ts-ignore
                        mr: "0.5rem",
                    })}
                {loading && <Spin />}
                {children}
                {isValidElement(endIcon) &&
                    cloneElement(endIcon, {
                        // @ts-ignore
                        ml: "0.5rem",
                    })}
            </>
        </StyledButton>
    );
};

Button.defaultProps = {
    isLoading: false,
    external: false,
    variant: variants.PRIMARY,
    scale: scales.MD,
    disabled: false,
};

export default Button;
