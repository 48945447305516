
import { Form, Input, InputNumber, Select, Spin, Tooltip, } from "antd";
import * as React from "react";
import { isAddress } from 'utils';

import { Button } from "@pancakeswap/uikit";
import { EquivalentPayment, NoData, WrapperScroll } from "components";
import { ORDER_TYPE, PAYMENT, formatNumberPayment } from "config";
import { useActiveWeb3React, useInfoAsset, useNftShareholderBook, useTransaction } from "hooks";
import { useModal } from "hooks/use-modal";
import { isApprovedForAll } from 'state/state-info-asset';
import { InfoAssetType } from "type";
import { ItemInfo, WrapperItemInfo } from "../item-info";
import { Note } from "../note";

interface FormType {
    quantity: number;
    share: string;
    wallet: string;
}

export const ModalTransfer = ({ balance }: { balance: number }) => {
    const [form] = Form.useForm();

    const { account, chainId } = useActiveWeb3React();
    const [dataItemInfo, setDataItemInfo] = React.useState<InfoAssetType>();
    const contractNft = useNftShareholderBook(dataItemInfo?.nft_address);
    const [isApprovalAllowance, setIsApprovalAllowance] = React.useState<boolean>(false);

    const { storeAsset } = useInfoAsset();
    const { loadingTx, handleTransferNft, handleApprovalNft } = useTransaction();
    const { handleCloseModal } = useModal();

    const onSubmit = async (value: FormType) => {
        if (dataItemInfo) {
            if (isApprovalAllowance) {
                const message = dataItemInfo.is_manual ? <p>Chuyển nhượng cổ phiếu đã hoàn tất. <br /> Vui lòng chờ xác nhận</p> : "Chuyển nhượng thành công";
                const description = `${formatNumberPayment(value.quantity)} cổ phiếu mã ${dataItemInfo.stocks_code} với giá ${formatNumberPayment(dataItemInfo.price)} ${PAYMENT}`;

                handleTransferNft([
                    dataItemInfo.nft_address,
                    dataItemInfo.id,
                    value.quantity,
                    ORDER_TYPE.TRANSFER,
                    value.wallet,
                ], message, description)
            }
            else {
                const { status } = await handleApprovalNft(contractNft, [dataItemInfo.contract_marketplace, true]);
                if (status === 200) {
                    setIsApprovalAllowance(true);
                }
            }
        }
    };

    React.useEffect(() => {
        if (storeAsset.data.length) {
            const firstItem = storeAsset.data[0];
            if (!dataItemInfo) {
                setDataItemInfo(firstItem)
                form.setFieldsValue({
                    share: firstItem.stocks_code,
                })
            }
        }
    }, [dataItemInfo, form, storeAsset.data]);

    React.useEffect(() => {
        (async () => {
            if (account && dataItemInfo) {
                const { is_Approved } = await isApprovedForAll(dataItemInfo.nft_address, account, dataItemInfo.contract_marketplace, chainId);
                setIsApprovalAllowance(is_Approved);
            }
        })()
    }, [account, chainId, dataItemInfo]);


    const onChangeSelect = (stocksCode) => {
        const item = storeAsset.data.find((items: InfoAssetType) => items.stocks_code === stocksCode);
        setDataItemInfo(item);
        form.resetFields(["quantity"])
    }
    if (storeAsset.loading) return (
        <div className="flex my-12 justify-center">
            <Spin size="large" />
        </div>
    )
    if (!dataItemInfo || !storeAsset.data.length) return <NoData />;

    return (
        <WrapperScroll className="max-h-[90vh]">
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
                scrollToFirstError
                disabled={loadingTx}
            >
                <Form.Item
                    label="Chọn mã cổ phiếu"
                    name="share"
                >
                    <Select
                        placeholder="Mã cổ phiếu!"
                        optionLabelProp="label"
                        className="w-full"
                        size="large"
                        showSearch
                        notFoundContent={<NoData />}
                        onChange={onChangeSelect}
                    >
                        {
                            storeAsset.data.map((item: InfoAssetType) => (
                                <Select.Option
                                    value={item.stocks_code}
                                    label={
                                        <Tooltip title={item.company?.length > 50 && item.company}>
                                            <div className="flex items-center gap-2 truncate">
                                                <p className="text-[#23262F] md:text-sm text-xs font-bold">{item.stocks_code}</p>
                                                <p className="text-[#777E90] md:text-sm text-xs font-bold truncate">{item.company}</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Tooltip title={item.company?.length > 50 && item.company} >
                                        <div className="flex gap-2 truncate">
                                            <p className="text-[#23262F] md:text-sm text-xs font-bold">{item.stocks_code}</p>
                                            <p className="text-[#23262F] md:text-sm text-xs truncate">{item.company}</p>
                                        </div>
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <WrapperItemInfo>
                        <ItemInfo title="Số lượng có sẵn" value={dataItemInfo.quantity} uti="Cổ phiếu" />
                        <ItemInfo title="Tổng giá trị" value={dataItemInfo.total_value} uti={PAYMENT} />
                        <ItemInfo title="Số dư khả dụng" value={balance} uti={PAYMENT} />
                        <div className="text-right">
                            <EquivalentPayment value={balance} />
                        </div>
                    </WrapperItemInfo>
                </Form.Item>
                <Form.Item
                    label="Số lượng"
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: "Nhập số lượng cần chuyển nhượng"
                        },
                    ]}
                >
                    <InputNumber
                        disabled={dataItemInfo.paused || loadingTx}
                        size="large"
                        bordered={false}
                        min={1}
                        max={dataItemInfo.quantity}
                        placeholder={`${formatNumberPayment(dataItemInfo.quantity)}`}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        addonAfter={
                            <p
                                aria-hidden
                                onClick={() => {
                                    form.setFieldsValue({
                                        quantity: dataItemInfo.quantity
                                    });
                                }}
                                className="text-[#B1B5C3] font-medium cursor-pointer text-xs uppercase mr-2 hover:text-slate-700"
                            >Max</p>
                        }
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    label="Địa chỉ ví người nhận"
                    name="wallet"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập nhập địa chỉ ví"
                        },
                        () => ({
                            validator(_, value) {
                                if (value && !isAddress(value.toLowerCase())) {
                                    return Promise.reject(new Error('Địa chỉ ví không hợp lệ!'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input placeholder="0x..." size="large" allowClear disabled={dataItemInfo.paused || loadingTx} />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <div>
                            <div className="grid grid-cols-2 md:gap-8 gap-2 md:mt-4 mt-0">
                                <Button
                                    disabled={loadingTx}
                                    onClick={handleCloseModal}
                                    style={{ border: "2px solid #E6E8EC" }}
                                    className="bg-white text-[#23262F]"
                                >
                                    Hủy
                                </Button>
                                <Button
                                    loading={loadingTx}
                                    disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length || loadingTx || dataItemInfo.paused || !form.isFieldsTouched(false)}
                                >
                                    {isApprovalAllowance ? "Chuyển nhượng" : "Chấp thuận"}
                                </Button>
                            </div>
                            {dataItemInfo.paused && <p className="text-[#EF1616] font-bold text-sm mt-4 text-center">Mã cổ phiếu đang tạm ngừng giao dịch</p>}
                        </div>
                    )}
                </Form.Item>
            </Form>
            <Note />
        </WrapperScroll>
    )
}

