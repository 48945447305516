import styled from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 12px 8px;
    background: ${({ theme }) => theme.colors.background};
    border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
    padding-bottom: env(safe-area-inset-bottom);
    html[data-useragent*="TokenPocket_iOS"] & {
        padding-bottom: 45px;
    }
    z-index: 20;
`;

export default StyledBottomNav;
