import { ParamsGetApiHistory } from "type";
import axiosClient from "./axios-client";


export const apiHistory = {
    getApiHistory: (params: ParamsGetApiHistory) => {
        const url = "v1/trade-history";
        return axiosClient.get(url, { params });
    }
};

