import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { InfoAssetType, InitialStateAssetType } from 'type';
import { actionDataCreateBuyOrder, actionGetDataInfoAsset, actionLoadingDataInfoAsset, actionTransactionHashInfoAsset } from './actions';

const initialState: InitialStateAssetType = {
    loading: true,
    data: [],
    transactionHash: null,
    dataCreateBuyOrder: []
};

export const stateInfoAsset = createReducer(initialState, (builder) =>
    builder
        .addCase(actionGetDataInfoAsset, (state: InitialStateAssetType, action: PayloadAction<InfoAssetType[]>) => {
            state.data = action.payload;
        })
        .addCase(actionDataCreateBuyOrder, (state: InitialStateAssetType, action: PayloadAction<InfoAssetType[]>) => {
            state.dataCreateBuyOrder = action.payload;
        })
        .addCase(actionLoadingDataInfoAsset, (state: InitialStateAssetType, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        })
        .addCase(actionTransactionHashInfoAsset, (state: InitialStateAssetType, action: PayloadAction<string>) => {
            state.transactionHash = action.payload;
        })
);
