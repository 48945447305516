import { ORDER_TYPE, STATUS_TYPE } from "config";
// eslint-disable-next-line lodash/import-scope
import _ from "lodash";
import { DataHistoryType } from "state/state-chairperson/type";

const getDuplicate = (array: DataHistoryType[], isRejectStatus:boolean) => {
    const result:any = array.reduce((carry, current) => {
            const { nftAddress, month, year, orderType, symbol,...data } = current;
            let el = null;
            if( isRejectStatus ) {
                el = carry.find((item) => item?.nftAddress?.toLowerCase() === nftAddress?.toLowerCase() && item?.month === month && item?.year === year);
            } else {
                el = carry.find((item) => item?.nftAddress?.toLowerCase() === nftAddress?.toLowerCase() && item?.month === month && item?.year === year && item?.orderType === orderType);
            }
            if (!el) {
                el = { nftAddress, month, year, orderType, symbol, items: [] }
                carry.push(el);
        }
        el.items.push({ ...data, nftAddress, month, year, orderType, symbol });
        return carry;
    }, []);

    const mergeData = result?.map((dt) => {
        const total = dt?.items.reduce((a, b)=> a + b?.quantity,0)
        if(isRejectStatus){
            return {
                nftAddress:dt.nftAddress,
                month: dt?.month,
                year: dt?.year,
                orderType: ORDER_TYPE.REJECT,
                symbol: dt?.symbol,
                quantity: total
            }
        }
        return {
            nftAddress:dt.nftAddress,
            month: dt?.month,
            year: dt?.year,
            orderType: dt?.orderType,
            symbol: dt?.symbol,
            quantity: total
        }
    })
    return mergeData
}
export const getObjectDuplidate = (arr: DataHistoryType[]) => {
    const filterReject = arr.filter((dt) => dt?.status === STATUS_TYPE.REJECTED)
    const normalArr = arr.filter((dt) => dt?.status !== STATUS_TYPE.REJECTED) 
    const mergeNormalArr = getDuplicate(normalArr, false)
    const mergeRejectArr = getDuplicate(filterReject, !false)
    return mergeNormalArr.concat(mergeRejectArr)
}