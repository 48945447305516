import { notification } from "antd";
import { getBlockExploreLink } from 'utils';
import useActiveWeb3React from "./useActiveWeb3React";

interface notification {
    message: string | React.ReactNode;
    txHash?: string;
    type?: string; // success | info | warning | error
    description?: string;
}

export const useNotification = () => {
    const { chainId } = useActiveWeb3React();
    return {
        notification: ({ message, txHash, type = "success", description }: notification) => notification[type]({
            message: <p className="text-[#23262F] text-base">{message}</p>,
            description: <div className="flex flex-col gap-1">
                {
                    description && <p className="text-[#777E90] text-sm">{description}</p>
                }
                {
                    txHash &&
                    <a
                        href={getBlockExploreLink(txHash, 'transaction', chainId)}
                        target="_blank" rel="noopener noreferrer"
                        className="truncate text-[#777E90] text-sm"
                    >
                        {`Tx: ${txHash}`}
                    </a>

                }
            </div>,
               style: {
                width: 450,
              },
        })
        
    }
};

