import * as React from 'react';

import { renderMarketplaceAddress, useActiveWeb3React, useInfoOrder } from "hooks";
import { useAppDispatch } from "state";
import { DataGetApiOrder, GetOrderAsListType } from 'type';
import { PAGE_SIZE_ORDER } from 'config';
import { getDataOrder } from './fetch-data';
import { onGetDataChainOrder, onGetDataOrder, onLoadingOrder, } from './order-slice';

export * from "./patch-api";

export const useStateOrder = () => {
    const dispatch = useAppDispatch();
    const { chainId, account } = useActiveWeb3React();
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const { handleGetApiOrder, storeOrder } = useInfoOrder();

    React.useEffect(() => {
        (async () => {
            const mapData = storeOrder.dataChainOrder.map((tx: GetOrderAsListType) => {
                const matchingOrder = storeOrder.dataApiOrder.find((ord: DataGetApiOrder) => tx.order_id === ord._id);
                return {
                    ...tx,
                    _id: matchingOrder ? matchingOrder._id : null,
                    transaction: matchingOrder ? matchingOrder.transaction : storeOrder.transactionHash
                };
            });
            dispatch(onGetDataOrder(mapData));
        })();
    }, [dispatch, storeOrder.dataApiOrder, storeOrder.dataChainOrder]);


    const handleFetchData = React.useCallback(async () => {
        const resultGetDataOrder: GetOrderAsListType[] = await getDataOrder(marketplaceAddress, account, chainId);
        dispatch(onGetDataChainOrder(resultGetDataOrder));
        const orderId = storeOrder.dataOrderId.length ? storeOrder.dataOrderId : resultGetDataOrder.slice(0, PAGE_SIZE_ORDER).map((item: GetOrderAsListType) => (item.order_id));
        await handleGetApiOrder({ orderId, chainId });
        dispatch(onLoadingOrder(false));
    }, [account, chainId, dispatch, handleGetApiOrder, marketplaceAddress, storeOrder.dataOrderId])

    React.useEffect(() => {
        (async () => {
            if (account) {
                dispatch(onLoadingOrder(true));
                await handleFetchData();
            } else {
                dispatch(onLoadingOrder(false));
                dispatch(onGetDataOrder([]));
                dispatch(onGetDataChainOrder([]));
            }
        })();
    }, [account, dispatch, handleFetchData, storeOrder.transactionHash]);
};
