import * as React from 'react';
import { MaxUint256 } from '@ethersproject/constants'

import { storeTransaction as reducerTransaction, useAppDispatch, useAppSelector } from 'state';
import { actionTransactionHashTransaction, actionTransactionSortStocks } from 'state/state-transaction';

import { actionTransactionHashInfoAsset } from 'state/state-info-asset';
import { actionTransactionHashAccount } from 'state/state-account';
import { onTransactionHashOrder } from 'state/state-info-order/order-slice';

import { fetchTxHash } from 'state/state-chairperson/actions';
import { renderMarketplaceAddress, renderTokenPayment } from './render-token';
import useActiveWeb3React from './useActiveWeb3React';
import { useCallWithMarketGasPrice } from './useCallWithMarketGasPrice';
import { useMarketplaceShareholderBook, useTokenContract } from './useContract';
import { useNotification } from './use-notification';
import { useModal } from './use-modal';
import { useRejected } from './use-rejected';

export type CreateSellOrderType = [string, number, number, number, number, number];
export type BuyNowType = [number, number];
export type CreateBuyOrderType = [string, number, number, number, number, number];
export type SellNowType = [number, number];
export type CancelOrderType = [number, number];
export type TransferNftType = [string, number, number, number, string];

export const useTransaction = () => {
    const dispatch = useAppDispatch();
    const { chainId } = useActiveWeb3React();
    const storeTransaction = useAppSelector(reducerTransaction);

    const [loadingTx, setLoadingTx] = React.useState<boolean>(false);

    const { notification } = useNotification();
    const { handleRejected } = useRejected();
    const { handlePauseCloseModal, handleCloseModal } = useModal();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();

    const tokenPayment = renderTokenPayment(chainId);
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const contractMarketplace = useMarketplaceShareholderBook(marketplaceAddress);
    const contractTokenPayment = useTokenContract(tokenPayment.address);

    const handleCreateSellOrder = React.useCallback(async (payload: CreateSellOrderType, message: string, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "createOrder", [payload]);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message,
                    description,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);

            console.log('error:', error)
            console.log('payload:', payload);
            console.log('contractMarketplace:', contractMarketplace);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleCancelOrder = React.useCallback(async (payload: CancelOrderType, message: string, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "cancelOrder", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    description,
                    message,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);

            console.log('error', error);
            console.log('error:', error)
            console.log('payload:', payload);
            console.log('contractMarketplace:', contractMarketplace);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleCreateBuyOrder = React.useCallback(async (payload: CreateBuyOrderType, message: string, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "createOrder", [payload]);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    description,
                    message,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            console.log('error', error)
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);

            console.log('error:', error)
            console.log('payload:', payload);
            console.log('contractMarketplace:', contractMarketplace);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleTransferNft = React.useCallback(async (payload: TransferNftType, message: string| React.ReactNode, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "transfer", [payload]);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message,
                    description,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            console.log('error', error)
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleApprovalNft = React.useCallback(async (contractNft, payload) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractNft, "setApprovalForAll", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message: "Chấp thuận thành công",
                    txHash: receipt.transactionHash,
                });
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
            return {
                status: receipt.status ? 200 : 404,
            }
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
            return {
                status: 404,
            }
        }
    }, [callWithMarketGasPrice, dispatch, handlePauseCloseModal, handleRejected, notification]);

    const handleTransactionSortStocks = React.useCallback((stocks) => dispatch(actionTransactionSortStocks(stocks)), [dispatch]);

    const handleBuyNow = React.useCallback(async (payload: BuyNowType, message: string| React.ReactNode, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "buyNow", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message,
                    description,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleSellNow = React.useCallback(async (payload: SellNowType, message: string, description: string) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "sellNow", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    description,
                    message,
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            console.log('payload', payload)
            console.log('contractMarketplace', contractMarketplace)
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleCancelAllExpireOrder = React.useCallback(async (payload) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractMarketplace, "cancelAllExpireOrder", [payload]);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message: "Đã hủy thành công các lệnh đã hết hạn",
                    txHash: receipt.transactionHash,
                });
                dispatch(fetchTxHash({txHash: receipt.transactionHash}))
                dispatch(actionTransactionHashTransaction(receipt.transactionHash));
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
            return {
                status: receipt.status ? 200 : 401,
            }
        } catch (error) {
            console.log('payload', payload)
            console.log('contractMarketplace', contractMarketplace)
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
            return {
                status: 401
            }
        }
    }, [callWithMarketGasPrice, contractMarketplace, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    const handleApprovalAllowance = React.useCallback(async () => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractTokenPayment, "approve", [marketplaceAddress, MaxUint256]);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message: "Chấp thuận thành công",
                    txHash: receipt.transactionHash,
                });
                dispatch(actionTransactionHashAccount(receipt.transactionHash));
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
        }

    }, [callWithMarketGasPrice, contractTokenPayment, dispatch, handlePauseCloseModal, handleRejected, marketplaceAddress, notification]);

    return {
        storeTransaction,
        loadingTx,
        handleCreateSellOrder,
        handleTransactionSortStocks,
        handleApprovalNft,
        handleBuyNow,
        handleSellNow,
        handleApprovalAllowance,
        handleCreateBuyOrder,
        handleCancelOrder,
        handleTransferNft,
        handleCancelAllExpireOrder
    }
}
