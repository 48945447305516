import ReactHTMLTableToExcel from "react-html-table-to-excel";

interface WrapperDownloadExcelType {
    children: React.ReactNode,
    filename: string,
    selectTable: string
}
export const WrapperDownloadExcel = ({ children, filename, selectTable }: WrapperDownloadExcelType) => {
    return (
        <div className="relative">
            <ReactHTMLTableToExcel
                className="absolute w-full h-full"
                table={selectTable}
                filename={filename}
                sheet={filename}
                buttonText="" />
            {children}
        </div>
    )
}
