import { createAction } from '@reduxjs/toolkit';
import { ChairpersonType, ListApproverType } from 'type';
import { AdminChairperson, ApprovingItem, InfoStocksType, StatisticsType, StockItem } from './type';

export const actionGetDataChairperson = createAction<ChairpersonType[]>("actionGetDataChairperson");
export const fectAdminChairperson = createAction<{ listApprover: AdminChairperson[] }>("fectAdminChairperson");
export const fetchDataApproving = createAction<{ dataApproving: ApprovingItem[] }>("fetchDataApproving");
export const fetchDataInfoStocks = createAction<{ infoStocks: InfoStocksType[] }>("fetchDataInfoStocks");
export const fetchTxHash = createAction<{ txHash: string }>("fetchTxHash");
export const fetchDataStock = createAction<{ stock: StockItem }>("fetchDataStock");
export const fetchStatistics = createAction<{ statistics: StatisticsType[] }>("fetchStatistics");

export const actionGetHistoryApprovers = createAction<ListApproverType[]>("actionGetHistoryApprovers");

