import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiHistory } from "api";
import BigNumber from "bignumber.js";
import { ORDER_TYPE, STATUS_TYPE, checkOrderType, checkDefaultStatus, formatDate } from "config";
import { getStockSymbol } from "state/state-info-asset";
import { InfoHistoryType, ParamsGetApiHistory } from "type";

export const onGetApiHistory = createAsyncThunk("onGetApiHistory", async (params: ParamsGetApiHistory) => {
    const response: any = await apiHistory.getApiHistory(params);
    if (response?.statusCode === 200) {
        const payload = response.data.orders;
        const result = await Promise.all((await payload.map(async (item, index) => {
            const stocks_code = await getStockSymbol(item.nftAddress, params.chainId);
            return {
                index: index + 1,
                block_number: item.blockNumber,
                times_tamp: item.timestamp * 1000,
                amount: checkOrderTypeAmount(item),
                chain_id: item.chainId,
                create_time: item.createTimestamp * 1000,
                created_at: item.createdAt,
                end_time: item.endTimestamp * 1000,
                event: item.event,
                matched_quantity: item.matchedQuantity,
                nft_address: item.nftAddress,
                order_creator: item.orderCreator,
                order_id: item.orderId,
                order_type: item.orderType,
                order_code: checkOrderType(item.orderType),
                original_quantity: checkOrderTypeOriginalQuantity(item),
                quantity: item.quantity,
                receiver: item.receiver,
                sender: item.sender,
                status: item.status,
                status_code: checkDefaultStatus(item.status),
                target_order_id: item.targetOrderId,
                token_id: item.tokenId,
                transaction: item.transaction,
                unit_price: Number(item.unitPrice),
                updated_at: item.updatedAt,
                _id: item._id,
                is_approver: item.isApprover,
                approver: item?.approver,
                is_manual: item?.isManual,
                ...stocks_code,
            }
        })));
        const data = result.map((item: InfoHistoryType) => {
            return {
                ...item,
                total_value: new BigNumber(item.unit_price).multipliedBy(item.matched_quantity > 0 ? item.matched_quantity : item.original_quantity).toNumber(),
                times_tamp_string: formatDate(item.times_tamp),
                status: checkStatusClient(!!params?.client, item),
                status_code: checkDefaultStatus(checkStatusClient(!!params?.client, item)),
                amount: item.order_type === ORDER_TYPE.TRANSFER ? item.original_quantity : item.amount
            }
        });

        const filterAmount = params?.client ? data.filter(item => item.amount > 0) : data;
        const total = params?.client
            ? response.data.total - (data.length - filterAmount.length)
            : response.data.total;

        return {
            data: [...filterAmount].sort((a, b) => b.times_tamp - a.times_tamp),
            total,
            total_quantity: response.data.totalQuantity
        };
    }

    return {
        data: [],
        total: 0
    };
});

export const onGetApiHistoryDownload = createAsyncThunk("onGetApiHistoryDownload", async (params: ParamsGetApiHistory) => {
    const response: any = await apiHistory.getApiHistory(params);
    if (response?.statusCode === 200) {
        const payload = response.data.orders;
        const result = await Promise.all((await payload.map(async (item, index) => {
            const stocks_code = await getStockSymbol(item.nftAddress, params.chainId);
            return {
                index: index + 1,
                block_number: item.blockNumber,
                times_tamp: item.timestamp * 1000,
                amount: checkOrderTypeAmount(item),
                chain_id: item.chainId,
                create_time: item.createTimestamp * 1000,
                created_at: item.createdAt,
                end_time: item.endTimestamp * 1000,
                event: item.event,
                matched_quantity: item.matchedQuantity,
                nft_address: item.nftAddress,
                order_creator: item.orderCreator,
                order_id: item.orderId,
                order_type: item.orderType,
                order_code: checkOrderType(item.orderType),
                original_quantity: checkOrderTypeOriginalQuantity(item),
                quantity: item.quantity,
                receiver: item.receiver,
                sender: item.sender,
                status: item.status,
                status_code: checkDefaultStatus(item.status),
                target_order_id: item.targetOrderId,
                token_id: item.tokenId,
                transaction: item.transaction,
                unit_price: Number(item.unitPrice),
                updated_at: item.updatedAt,
                _id: item._id,
                is_approver: item.isApprover,
                approver: item?.approver,
                is_manual: item?.isManual,
                ...stocks_code,

            }
        })));
        const data = result.map((item: InfoHistoryType) => {
            return {
                ...item,
                total_value: new BigNumber(item.unit_price).multipliedBy(item.matched_quantity > 0 ? item.matched_quantity : item.original_quantity).toNumber(),
                times_tamp_string: formatDate(item.times_tamp),
                status: checkStatusClient(!!params?.client, item),
                status_code: checkDefaultStatus(checkStatusClient(!!params?.client, item)),
                amount: item.order_type === ORDER_TYPE.TRANSFER ? item.original_quantity : item.amount
            }
        });
        const filterAmount = params?.client ? data.filter(item => item.amount > 0) : data;
        const total = params?.client
            ? response.data.total - (data.length - filterAmount.length)
            : response.data.total;

        return {
            data: [...filterAmount].sort((a, b) => b.times_tamp - a.times_tamp),
            total,
            total_quantity: response.data.totalQuantity
        };
    }

    return {
        data: [],
        total: 0
    };
});


const checkOrderTypeOriginalQuantity = (item) => {
    if (item.orderType === ORDER_TYPE.BUY_NOW || item.orderType === ORDER_TYPE.SELL_NOW) {
        return item.quantity || item.originalQuantity
    }
    return item.originalQuantity
}

const checkOrderTypeAmount = (item) => {
    if (item.orderType === ORDER_TYPE.BUY_NOW || item.orderType === ORDER_TYPE.SELL_NOW) {
        return item.quantity || item.matchedQuantity
    }
    if (item.status === STATUS_TYPE.CANCELLED) {
        return item.matchedQuantity
    }
    return item.matchedQuantity
}

const checkStatusClient = (client, item: InfoHistoryType) => {
    if (!item.is_manual) return item.status;
    if (client) return item.status === STATUS_TYPE.CREATED ? STATUS_TYPE.COMPLETED : item.status;
    return item.status === STATUS_TYPE.COMPLETED ? STATUS_TYPE.CREATED : item.status;
}
