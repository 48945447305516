import { Link, Text } from '@pancakeswap/uikit'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import truncateHash from '@pancakeswap/utils/truncateHash'

interface DescriptionWithTxProps {
    description?: string
    txHash?: string
    txChainId?: number
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({
    txHash,
    txChainId,
    children,
}) => {
    const { chainId } = useActiveWeb3React()
    const { t } = useTranslation()

    return (
        <>
            {typeof children === 'string' ? <Text as="p" color='text90'>{children}</Text> : children}
            {txHash && (
                <Link color='text90' external href={getBlockExploreLink(txHash, 'transaction', txChainId || chainId)}>
                    {t('View on %site%', { site: getBlockExploreName(txChainId || chainId) })}:{' '}
                    {truncateHash(txHash, 8, 0)}
                </Link>
            )}
        </>
    )
}

export default DescriptionWithTx
