import React from 'react';

import { storeModal, useAppDispatch, useAppSelector } from 'state';
import { openModal, pauseCloseModal } from 'state/modal/modal-slice';
import { ModalType } from 'type';
import { ModalTypeEnum } from 'config';


export const useModal = () => {
    const resultModal = useAppSelector(storeModal);
    const { isPauseClose } = resultModal;
    const dispatch = useAppDispatch();
    const toggleModal = React.useCallback((data: ModalType) => dispatch(openModal(data)), [dispatch]);

    const handlePauseCloseModal = React.useCallback((pause: boolean) => {
        dispatch(pauseCloseModal(pause));
    }, [dispatch]);

    const handleCloseModal = () => {
        if (!isPauseClose) {
            toggleModal({
                title: "",
                type: ModalTypeEnum.NULL
            })
        }
    };

    return {
        resultModal,
        ModalTypeEnum,
        handlePauseCloseModal,
        handleCloseModal,
        toggleModal,
    }
}
