import { Form, InputNumber } from 'antd';
import * as React from 'react';

import { Button } from '@pancakeswap/uikit';
import { NoData, WrapperScroll } from 'components';
import { useTransaction } from 'hooks';
import { InfoOrderType } from 'type';
import { PAYMENT, STATUS_TYPE, formatNumberPayment } from 'config';
import { ItemInfo, WrapperItemInfo } from '../item-info';

export const ModalCancelOrderBook = (props: InfoOrderType) => {
    const [form] = Form.useForm();
    const { handleCancelOrder, loadingTx } = useTransaction();

    React.useEffect(() => {
        if (props.remaining_quantity) {
            form.setFieldsValue({
                quantity: props.remaining_quantity
            })
        }
    }, [props.remaining_quantity, form]);

    const onSubmit = ({ quantity }) => {
        const message = props.is_manual ? "Hủy thành công" : "Hủy thành công";
        const description = `${formatNumberPayment(quantity)} cổ phiếu mã ${props.stocks_code} với giá ${formatNumberPayment(props.unit_price)} ${PAYMENT}`;
        handleCancelOrder([props.order_id, quantity], message, description);
    };
    if (!props) return <NoData />

    return (
        <WrapperScroll className="max-h-[80vh]">
            <WrapperItemInfo className="mb-8">
                <ItemInfo title="Mã cổ phiếu" value={props.stocks_code} />
                <ItemInfo title="Số lượng" value={props.remaining_quantity} uti="Cổ phiếu" />
            </WrapperItemInfo>
            <Form
                form={form}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
                onFinish={onSubmit}
            >
                <Form.Item
                    label="Số lượng"
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập số lượng"
                        }
                    ]}
                >
                    <InputNumber
                        readOnly={props.status === STATUS_TYPE.PENDING || props.is_expired}
                        disabled={props.paused || loadingTx}
                        min={1}
                        size="large"
                        max={props.remaining_quantity}
                        bordered={false}
                        addonAfter={
                            <p
                                aria-hidden
                                className="text-[#B1B5C3] font-medium cursor-pointer text-xs uppercase mr-2 hover:text-slate-700  z-20"
                                onClick={() => {
                                    form.setFieldsValue({
                                        quantity: props.remaining_quantity
                                    })
                                }}
                            >Max</p>
                        }
                        placeholder="0"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <div>
                            <Button
                                loading={loadingTx}
                                className="md:mt-4 mt-0 w-full"
                                disabled={
                                    !form.isFieldsTouched(true) || loadingTx ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length || props.paused
                                }
                            >
                                Xác nhận hủy
                            </Button>
                            {props.paused && <p className="text-[#EF1616] font-bold text-sm mt-4 text-center">Mã cổ phiếu đang tạm ngừng giao dịch</p>}
                        </div>
                    )}
                </Form.Item>
            </Form>
        </WrapperScroll>
    )
}
