import clsx from 'clsx';

interface WrapperTabType {
    data: string[],
    activeTab: number;
    setActiveTab: (tab: number) => void;
    className?: string;
    classActive?: string,
    classNotActive?: string,
}

interface ItemTabType {
    active: boolean;
    onClick: () => void;
    title: string;
}

export const WrapperTab = ({
    data,
    activeTab,
    setActiveTab,
    className,
    classNotActive = "text-[#23262F] hover:bg-[#23262F] hover:text-white",
    classActive = "bg-[#23262F] text-white"
}: WrapperTabType) => {

    const ItemTab = ({ active, onClick, title }: ItemTabType) => {
        return (
            <p
                aria-hidden
                onClick={onClick && onClick}
                className={clsx(
                    "sm:px-[20px] px-0 py-[10px] text-base font-bold cursor-pointer rounded-lg text-center",
                    active ? classActive : classNotActive,
                    className
                )}
            >{title}</p>
        )
    };

    return (
        <div className={clsx("p-[10px] bg-[#F8F8F8] rounded-lg flex gap-1", className)}>
            {
                data.map((item, index) => (
                    <ItemTab
                        key={item}
                        title={item}
                        active={index === activeTab}
                        onClick={() => setActiveTab(index)}
                    />
                ))}
        </div>
    )
}
