import React, { PropsWithChildren, useRef } from "react";
import styled, { useTheme } from "styled-components";
import Heading from "../../components/Heading/Heading";
import getThemeValue from "../../util/getThemeValue";
import { ModalBody, ModalHeader, ModalTitle, ModalContainer, ModalCloseButton, ModalBackButton } from "./styles";
import { ModalProps, ModalWrapperProps } from "./types";
import { useMatchBreakpoints } from "../../contexts";

export const MODAL_SWIPE_TO_CLOSE_VELOCITY = 300;

export const ModalWrapper = ({ children, onDismiss, minWidth, ...props }: PropsWithChildren<ModalWrapperProps>) => {
    const { isMobile } = useMatchBreakpoints();
    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        // @ts-ignore
        <ModalContainer
            drag={isMobile ? "y" : false}
            dragConstraints={{ top: 0, bottom: 600 }}
            dragElastic={{ top: 0 }}
            dragSnapToOrigin
            onDragStart={() => {
                if (wrapperRef.current) wrapperRef.current.style.animation = "none";
            }}
            onDragEnd={(e, info) => {
                if (info.velocity.y > MODAL_SWIPE_TO_CLOSE_VELOCITY && onDismiss) onDismiss();
            }}
            ref={wrapperRef}
            minWidth={minWidth}
            {...props}
        >
            {children}
        </ModalContainer>
    );
};

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    title,
    onDismiss,
    onBack,
    children,
    hideCloseButton = false,
    bodyPadding = "24px",
    headerBackground = "transparent",
    minWidth = "320px",
    ...props
}) => {
    const theme = useTheme();
    return (
        <ModalWrapper minWidth={minWidth} onDismiss={onDismiss} {...props}>
            <CsModalHeader background={getThemeValue(theme, `colors.${headerBackground}`, headerBackground)}>
                <ModalTitle>
                    {onBack && <ModalBackButton onBack={onBack} />}
                    <h3 className="font-bold md:text-[26px] text-xl text-[#23262F]">{title}</h3>
                </ModalTitle>
                {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
            </CsModalHeader>
            <ModalBody p={bodyPadding}>{children}</ModalBody>
        </ModalWrapper>
    );
};

export default Modal;

const CsModalHeader = styled(ModalHeader)`
    display: contents;
`;
