import clsx from "clsx"

interface WrapperItemFormType {
    children: React.ReactNode,
    title: string,
    className?: string,
}

export const WrapperItemForm = ({ children, title, className }: WrapperItemFormType) => {
    return (
        <div className={clsx("flex flex-col w-full gap-3", className)}>
            <p className="text-[#B1B5C3] font-bold text-sm uppercase">{title}</p>
            {children}
        </div>
    )
}
