import { ChainId } from '@pancakeswap/sdk'
import { getNodeRealUrlV2 } from './nodeReal'

export const SERVER_NODES = {
    [ChainId.BSC]: [
        process.env.NEXT_PUBLIC_NODE_PRODUCTION,
        'https://bsc-dataseed1.defibit.io',
        'https://bsc-dataseed1.binance.org',
    ].filter(Boolean),
    [ChainId.BSC_TESTNET]: ['https://data-seed-prebsc-2-s1.bnbchain.org:8545'],
    [ChainId.ETHEREUM]: [
        getNodeRealUrlV2(ChainId.ETHEREUM, process.env.SERVER_NODE_REAL_API_ETH),
        'https://eth.llamarpc.com',
        'https://cloudflare-eth.com',
    ],
    [ChainId.GOERLI]: [
        getNodeRealUrlV2(ChainId.GOERLI, process.env.SERVER_NODE_REAL_API_GOERLI),
        'https://eth-goerli.public.blastapi.io',
    ].filter(Boolean),
    [ChainId.ETHW_MAINNET]: ['https://mainnet.ethereumpow.org'],
    [ChainId.ONUS]: ['https://rpc.onuschain.io/'],
    [ChainId.ONUS_TESTNET]: ['https://rpc-testnet.onuschain.io'],
    [ChainId.RINKEBY]: ['https://rpc.ankr.com/eth_rinkeby	'],
} as Record<ChainId, readonly string[]>

export const PUBLIC_NODES = {
    [ChainId.BSC]: [
        process.env.NEXT_PUBLIC_NODE_PRODUCTION,
        getNodeRealUrlV2(ChainId.BSC, process.env.NEXT_PUBLIC_NODE_REAL_API_ETH),
        'https://bsc-dataseed1.defibit.io',
        'https://bsc-dataseed1.binance.org',
    ].filter(Boolean),
    [ChainId.BSC_TESTNET]: ['https://data-seed-prebsc-2-s1.bnbchain.org:8545'],
    [ChainId.ETHEREUM]: [
        getNodeRealUrlV2(ChainId.ETHEREUM, process.env.NEXT_PUBLIC_NODE_REAL_API_ETH),
        'https://eth.llamarpc.com',
        'https://cloudflare-eth.com',
    ].filter(Boolean),
    [ChainId.GOERLI]: [
        getNodeRealUrlV2(ChainId.GOERLI, process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI),
        'https://eth-goerli.public.blastapi.io',
    ].filter(Boolean),
    [ChainId.ETHW_MAINNET]: ['https://mainnet.ethereumpow.org'],
    [ChainId.ONUS]: ['https://rpc.onuschain.io/'],
    [ChainId.ONUS_TESTNET]: ['https://rpc-testnet.onuschain.io'],
    [ChainId.RINKEBY]: ['https://rpc.ankr.com/eth_rinkeby	'],
} as Record<ChainId, readonly string[]>
