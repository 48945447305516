
export const IconCreateSell = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M10.6953 11.9985H6.94531" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.4957 1.88811L9.4732 1.94061L7.2982 6.98811H5.1607C4.6507 6.98811 4.1632 7.09311 3.7207 7.28061L5.0332 4.14561L5.0632 4.07061L5.1157 3.95061C5.1307 3.90561 5.1457 3.86061 5.1682 3.82311C6.1507 1.55061 7.2607 1.03311 9.4957 1.88811Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5369 7.13867C13.1994 7.03367 12.8394 6.98867 12.4794 6.98867H7.29688L9.47187 1.94117L9.49437 1.88867C9.60687 1.92617 9.71187 1.97867 9.82437 2.02367L11.4819 2.72117C12.4044 3.10367 13.0494 3.50117 13.4394 3.98117C13.5144 4.07117 13.5744 4.15367 13.6269 4.25117C13.6944 4.35617 13.7469 4.46117 13.7769 4.57367C13.8069 4.64117 13.8294 4.70867 13.8444 4.76867C14.0469 5.39867 13.9269 6.17117 13.5369 7.13867Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.1423 10.6488V12.1113C16.1423 12.2613 16.1348 12.4113 16.1273 12.5613C15.9848 15.1788 14.5223 16.4988 11.7473 16.4988H5.89727C5.71727 16.4988 5.53727 16.4838 5.36477 16.4613C2.97977 16.3038 1.70477 15.0288 1.54727 12.6438C1.52477 12.4713 1.50977 12.2913 1.50977 12.1113V10.6488C1.50977 9.14127 2.42477 7.84377 3.72977 7.28127C4.17977 7.09377 4.65977 6.98877 5.16977 6.98877H12.4898C12.8573 6.98877 13.2173 7.04127 13.5473 7.13877C15.0398 7.59627 16.1423 8.99127 16.1423 10.6488Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.0325 4.146L3.72 7.281C2.415 7.8435 1.5 9.141 1.5 10.6485V8.451C1.5 6.321 3.015 4.5435 5.0325 4.146Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.1399 8.45123V10.6487C16.1399 8.99873 15.0449 7.59623 13.5449 7.14623C13.9349 6.17123 14.0474 5.40623 13.8599 4.76873C13.8449 4.70123 13.8224 4.63373 13.7924 4.57373C15.1874 5.29373 16.1399 6.77123 16.1399 8.45123Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
