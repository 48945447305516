import clsx from "clsx";

interface WrapperSelectType {
    title: string,
    children: React.ReactNode,
    className?: string
}

export const WrapperSelect = ({
    title,
    children,
    className = "sm:max-w-xs"
}: WrapperSelectType) => (
    <div className={clsx("flex flex-col w-full", className)}>
        <p className="uppercase text-[#B1B5C3] text-xs font-bold mb-3">{title}</p>
        {children}
    </div>
);
