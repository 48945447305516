import * as React from 'react';
import { Form, InputNumber } from "antd";
import BigNumber from 'bignumber.js';


import ConnectWalletButton from "components/ConnectWalletButton";
import { Button } from "@pancakeswap/uikit";
import { PAYMENT, formatNumberPayment } from "config";
import { renderMarketplaceAddress, useAccount, useInfoAsset, useModal, useNftShareholderBook, useTransaction } from "hooks";
import { TransactionType } from "type";

import { EquivalentPayment, NoData, WrapperItemForm, WrapperScroll } from "components";
import { isApprovedForAll, useStateInfoAsset } from 'state/state-info-asset';
import { ItemInfo, WrapperItemInfo } from "../item-info";
import { Note } from "../note";


export const ModalSellShares = (props: TransactionType) => {
    const [form] = Form.useForm();
    useStateInfoAsset();
    const { storeAccount, account, chainId } = useAccount();
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const contractNft = useNftShareholderBook(props?.nft_address);

    const { loadingTx, handleApprovalNft, handleSellNow } = useTransaction();
    const { handleCloseModal } = useModal();
    const { storeAsset } = useInfoAsset();

    const [quantity, setQuantity] = React.useState<number>(0);
    const [isApprovalAllowance, setIsApprovalAllowance] = React.useState<boolean>(false);

    const totalPrice = React.useMemo(() => new BigNumber(quantity || 0).multipliedBy(props.unit_price || 0).toNumber(), [props.unit_price, quantity]);

    const isTotalMoneyOfBalance = React.useMemo(() => {
        return new BigNumber(totalPrice).isGreaterThan(storeAccount.data.balance);
    }, [storeAccount.data.balance, totalPrice]);

    const itemAsset = React.useMemo(() => {
        return storeAsset.data.find((item) => item.stocks_code === props.stocks_code);
    }, [props.stocks_code, storeAsset.data]);

    const onSubmit = async (value: { quantity: number }) => {
        if (account) {
            if (isApprovalAllowance) {
                const message = props.is_manual ? "Bán cổ phiếu đã hoàn tất. Vui lòng chờ xác nhận" : "Bán thành công";
                const description = `${formatNumberPayment(value.quantity)} cổ phiếu mã ${props.stocks_code} với giá ${formatNumberPayment(props.unit_price)} ${PAYMENT}`;
                handleSellNow([props.order_id, value.quantity], message, description);
            } else {
                const { status } = await handleApprovalNft(contractNft, [marketplaceAddress, true]);
                if (status === 200) {
                    setIsApprovalAllowance(true);
                }
            }
        }
    };

    React.useEffect(() => {
        (async () => {
            if (account && props) {
                const { is_Approved } = await isApprovedForAll(props.nft_address, account, marketplaceAddress, chainId);
                setIsApprovalAllowance(is_Approved);
            }
        })()
    }, [account, chainId, marketplaceAddress, props]);

    if (!props) return <NoData />

    const isOrderCreator = props.order_creator.toLowerCase() === account?.toLowerCase();
    const quantityAsset = itemAsset?.quantity || 0;

    return (
        <WrapperScroll className="max-h-[80vh]">
            <WrapperItemInfo className="mb-8">
                <ItemInfo title="Mã cổ phiếu" value={props.stocks_code} />
                <ItemInfo title="Số lượng" value={props.quantity} uti="Cổ phiếu" />
                <ItemInfo title="Đơn giá" value={props.unit_price} uti={PAYMENT} />
                <ItemInfo title="Số lượng có sẵn" value={quantityAsset} uti="Cổ phiếu" />
                <ItemInfo title="Số dư khả dụng" value={storeAccount.data.balance} uti={PAYMENT} />
                <div className="text-right">
                    <EquivalentPayment value={storeAccount.data.balance} />
                </div>
            </WrapperItemInfo>
            <Form
                disabled={loadingTx || !itemAsset || !account}
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
                scrollToFirstError
            >
                <Form.Item
                    label="Số lượng"
                    name="quantity"
                    rules={[
                        {
                            required: !!account,
                            message: "Vui lòng nhập số lượng"
                        }
                    ]}
                >
                    <InputNumber
                        disabled={isOrderCreator || loadingTx || !itemAsset || !account}
                        min={1}
                        max={quantityAsset > props.quantity ? props.quantity : quantityAsset}
                        bordered={false}
                        placeholder={`${formatNumberPayment(quantityAsset > props.quantity ? props.quantity : quantityAsset)}`}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(value) => setQuantity(value)}
                        addonAfter={
                            <p
                                aria-hidden
                                onClick={() => {
                                    form.setFieldsValue({
                                        quantity: quantityAsset > props.quantity ? props.quantity : quantityAsset
                                    });
                                    setQuantity(quantityAsset > props.quantity ? props.quantity : quantityAsset);
                                }}
                                className="text-[#B1B5C3] font-medium cursor-pointer text-xs uppercase mr-2 hover:text-slate-700"
                            >Max</p>
                        }
                        size="large"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <WrapperItemForm
                    title="Số tiền thu về"
                    className="mb-6"
                >
                    <div>
                        <InputNumber
                            max={storeAccount.data.balance}
                            value={totalPrice}
                            status={isTotalMoneyOfBalance && "error"}
                            readOnly
                            size="large"
                            bordered={false}
                            placeholder="0"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            addonAfter={
                                <p className="text-[#B1B5C3] font-medium text-xs mr-2 hover:text-slate-700">{PAYMENT}</p>
                            }
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            className="font-bold text-sm w-full flex flex-row-reverse"
                            controls={false}
                        />
                        <div className="text-right mt-2">
                            <EquivalentPayment value={totalPrice} />
                        </div>
                        {isTotalMoneyOfBalance && <p className="relative text-[#FB1919] mt-3">Số tiền vượt quá số dư khả dụng</p>}
                    </div>
                </WrapperItemForm>
                <Form.Item shouldUpdate>
                    {() => (
                        <div className="flex flex-col gap-2">
                            <div className="grid grid-cols-2 md:gap-8 gap-2 md:mt-4 mt-0">
                                <Button
                                    disabled={loadingTx}
                                    onClick={handleCloseModal}
                                    style={{ border: "2px solid #E6E8EC" }}
                                    className="bg-white text-[#23262F]"
                                >
                                    Hủy
                                </Button>
                                {
                                    account
                                        ? <Button
                                            loading={loadingTx}
                                            disabled={
                                                !form.isFieldsTouched(true) || isTotalMoneyOfBalance || loadingTx ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                || !itemAsset || isOrderCreator
                                            }
                                        >
                                            {isApprovalAllowance ? "Bán" : "Chấp thuận"}
                                        </Button>
                                        : <ConnectWalletButton />
                                }
                            </div>
                            {isOrderCreator && quantityAsset > 0 && <p className="text-[#EF1616] font-bold text-sm mt-4 text-center">Tài sản này thuộc quyền sở hữu của bạn</p>}
                            {!itemAsset && <p className="text-[#FB1919] text-sm mt-3 text-center">Cổ phiếu: {props.stocks_code} không có trong tài sản của bạn</p>}
                        </div>
                    )}
                </Form.Item>
            </Form>
            <Note />
        </WrapperScroll>
    )
};
