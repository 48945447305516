import * as React from 'react';

import { renderMarketplaceAddress, useAccount, useModal } from "hooks";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useAppDispatch } from "state";
import { getApproverAccount, getDataAccount } from './fetch-data';
import { accountGetDataAccount, accountLoadingGetDataAccount, actionGetApproverAccount } from './actions';

export * from "./reducer";
export * from "./actions";

export const useStateAccount = () => {
    const dispatch = useAppDispatch();
    const { chainId, account } = useActiveWeb3React();
    const { storeAccount } = useAccount();
    const { handleCloseModal } = useModal();

    const marketplaceAddress = renderMarketplaceAddress(chainId);

    React.useLayoutEffect(() => {
        handleCloseModal();
    }, [account]);

    React.useEffect(() => {
        (async () => {
            try {
                if (account && chainId) {
                    dispatch(accountLoadingGetDataAccount(true))
                    const result = await getDataAccount(account, marketplaceAddress, chainId);
                    const approver = await getApproverAccount(account, chainId);

                    dispatch(actionGetApproverAccount(approver));
                    dispatch(accountGetDataAccount(result));
                    dispatch(accountLoadingGetDataAccount(false))
                } else {
                    dispatch(accountGetDataAccount({
                        allowance: 0,
                        balance: 0,
                        account: null
                    }));
                }
            } catch (error) {
                console.log('error get data account:', error)
                dispatch(accountLoadingGetDataAccount(false));
            }
        })()
    }, [account, chainId, dispatch, marketplaceAddress, storeAccount.transactionHash])
}
