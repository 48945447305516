import styled from "styled-components";
import { Text } from "../Text";

export const StyledBottomNavItem = styled.button`
    display: block;
    border: 0;
    padding: 4px;
    background: transparent;
    cursor: pointer;
    height: 44px;
    width: 70px;
    /* padding: 4px 12px;
  &:hover {
    border-radius: 8px;
  } */
    /* &:hover,
  &:hover div {
    background: ${({ theme }) => theme.colors.primaryBright};
    color:${({ theme }) => theme.colors.white};
  }
  &:hover div svg path {
    stroke:${({ theme }) => theme.colors.white} !important;
  } */
`;

export const StyledBottomNavText = styled(Text)<{ isActive: boolean }>`
    display: -webkit-box;
    overflow: hidden;
    user-select: none;
    // border-bottom:1px solid ${({ theme, isActive }) => (isActive ? theme.colors.text : "none")};
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    &::after {
        bottom: 1px;
        width: 70px;
        height: 2px;
        background: ${({ theme, isActive }) => (isActive ? theme.colors.text : "none")};
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        right: 0;
        content: "";
    }
`;
