import React from 'react'

export const IconDownload = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8347 4.16634C10.8347 3.7061 10.4616 3.33301 10.0014 3.33301C9.54114 3.33301 9.16805 3.7061 9.16805 4.16634V10.9049L6.84056 8.57743C6.51512 8.25199 5.98748 8.25199 5.66205 8.57743C5.33661 8.90286 5.33661 9.4305 5.66205 9.75594L8.82283 12.9167C9.4737 13.5676 10.529 13.5676 11.1798 12.9167L14.339 9.75757C14.6644 9.43213 14.6644 8.90449 14.339 8.57906C14.0136 8.25362 13.4859 8.25362 13.1605 8.57906L10.8347 10.9048V4.16634Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 11.667C3.79357 11.667 4.16667 12.0401 4.16667 12.5003V14.167C4.16667 14.6272 4.53976 15.0003 5 15.0003H15C15.4602 15.0003 15.8333 14.6272 15.8333 14.167V12.5003C15.8333 12.0401 16.2064 11.667 16.6667 11.667C17.1269 11.667 17.5 12.0401 17.5 12.5003V14.167C17.5 15.5477 16.3807 16.667 15 16.667H5C3.61929 16.667 2.5 15.5477 2.5 14.167V12.5003C2.5 12.0401 2.8731 11.667 3.33333 11.667Z" fill="white" />
        </svg>
    )
}
