import { createSlice, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { DataGetApiOrder, GetOrderAsListType, InfoOrderType } from 'type';
import { onGetApiOrder } from './patch-api';

interface InitialStateType {
    loading: boolean;
    dataOrderId: number[]
    data: InfoOrderType[],
    dataApiOrder: DataGetApiOrder[],
    dataChainOrder: GetOrderAsListType[],
    transactionHash: string;

}

const initialState: InitialStateType = {
    loading: true,
    data: [],
    dataApiOrder: [],
    dataChainOrder: [],
    transactionHash: null,
    dataOrderId: [],
};

const orderSlice = createSlice({
    name: "stateInfoOrder",
    initialState,
    reducers: {
        onGetDataChainOrder: (state: InitialStateType, action: PayloadAction<GetOrderAsListType[]>) => {
            state.dataChainOrder = action.payload;
        },
        onGetDataOrder: (state: InitialStateType, action: PayloadAction<InfoOrderType[]>) => {
            state.data = action.payload;
        },
        onTransactionHashOrder: (state: InitialStateType, action: PayloadAction<string>) => {
            state.transactionHash = action.payload;
        },
        onGetDataOrderId: (state: InitialStateType, action: PayloadAction<number[]>) => {
            state.dataOrderId = action.payload;
        },
        onLoadingOrder: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder
            .addCase(onGetApiOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(onGetApiOrder.fulfilled, (state: InitialStateType, action) => {
                state.dataApiOrder = action.payload.data;
                state.loading = false;
            })
            .addCase(onGetApiOrder.rejected, (state: InitialStateType) => {
                state.dataApiOrder = [];
                state.loading = false;
            })
    }
})
const { reducer, actions } = orderSlice;
export const {
    onGetDataChainOrder,
    onGetDataOrder,
    onTransactionHashOrder,
    onGetDataOrderId,
    onLoadingOrder
} = actions;
export default reducer;
