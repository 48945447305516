import * as React from 'react';

import { useAppDispatch } from 'state';
import { renderMarketplaceAddress, useActiveWeb3React, useTransaction } from "hooks";

import { getDataTransaction } from './fetch-data';
import { actionGetDataTransaction, actionLoadingDataTransaction } from './actions';

export * from "./reducer";
export * from "./actions";

export const useStateTransaction = () => {
    const dispatch = useAppDispatch();
    const { chainId } = useActiveWeb3React();
    const { storeTransaction } = useTransaction();
    const marketplaceAddress = renderMarketplaceAddress(chainId);

    const handleFetchData = React.useCallback(async () => {
        const result = await getDataTransaction(marketplaceAddress, chainId);
        dispatch(actionGetDataTransaction(result));
        dispatch(actionLoadingDataTransaction(false));
    }, [chainId, dispatch, marketplaceAddress]);

    React.useLayoutEffect(() => {
        (async () => {
            dispatch(actionLoadingDataTransaction(true));
            await handleFetchData();
        })()
    }, [dispatch, handleFetchData]);

    React.useEffect(() => {
        if (storeTransaction.transactionHash) {
            (async () => {
                await handleFetchData();
            })();
        }
    }, [handleFetchData, storeTransaction.transactionHash])

    React.useEffect(() => {
        const timer = setInterval(() => {
            handleFetchData();
            console.log('handleFetchData')
        }, 60000);
        return () => clearTimeout(timer);
    }, [handleFetchData]);
};
