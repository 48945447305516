import clsx from "clsx";
import { Tooltip } from 'antd';
import { IconWarning } from "icons";

interface TitleTableType {
    title: string,
    className?: string,
    onClick?: () => void,
    titleTooltip?: string,
    IconTooltip?: any,
    classNameIconTooltip?: string,
    classNameWrapper?: string,
    color?: string
}

interface ValueTableType {
    value: string | number | React.ReactNode,
    className?: string,
    onClick?: () => void,
    titleTooltip?: string,
    IconTooltip?: any,
    classNameIconTooltip?: string,
    classNameWrapper?: string,
    color?: string
}

export const TitleTableDesktop = ({ title, className, onClick, titleTooltip, IconTooltip = IconWarning, classNameIconTooltip, classNameWrapper, color = "text-[#23262F]" }: TitleTableType) => {
    return (
        <div className={clsx("flex gap-4", classNameWrapper)}>
            <h3 onClick={onClick && onClick} aria-hidden className={clsx("text-sm font-bold", className, color)}>{title}</h3>
            {titleTooltip &&
                <Tooltip placement="top" title={titleTooltip}>
                    <IconTooltip className={clsx("cursor-pointer", classNameIconTooltip)} />
                </Tooltip>
            }
        </div>
    )
};

export const ValueTableDesktop = ({ value, className, onClick, titleTooltip, IconTooltip = IconWarning, classNameIconTooltip, classNameWrapper, color = "text-[#5D5D5B]" }: ValueTableType) => {
    return (
        <div className={clsx("flex gap-2", classNameWrapper)}>
            <h3 onClick={onClick && onClick} aria-hidden className={clsx("text-sm font-bold", className, color)}>{value}</h3>
            {titleTooltip &&
                <Tooltip placement="top" title={titleTooltip}>
                    <IconTooltip className={clsx("cursor-pointer", classNameIconTooltip)} />
                </Tooltip>
            }
        </div>
    )
};

export const TitleTableMobile = ({ title, className, onClick, titleTooltip, IconTooltip = IconWarning, classNameIconTooltip, classNameWrapper, color = "text-[#23262F]" }: TitleTableType) => {
    return (
        <div className={clsx("flex gap-2", classNameWrapper)}>
            <h3 onClick={onClick && onClick} aria-hidden className={clsx("text-sm font-bold", className, color)}>{title}</h3>
            {titleTooltip &&
                <Tooltip placement="top" title={titleTooltip} trigger={["click"]}>
                    <IconTooltip className={clsx("cursor-pointer", classNameIconTooltip)} />
                </Tooltip>
            }
        </div>
    )
};

export const ValueTableMobile = ({ value, className, onClick, titleTooltip, IconTooltip = IconWarning, classNameIconTooltip, classNameWrapper, color = "text-[#23262F]" }: ValueTableType) => {
    return (
        <div className={clsx("flex gap-2", classNameWrapper)}>
            <h3 onClick={onClick && onClick} aria-hidden className={clsx("text-sm font-normal", className, color)}>{value}</h3>
            {titleTooltip &&
                <Tooltip placement="top" title={titleTooltip} trigger={["click"]}>
                    <IconTooltip className={clsx("cursor-pointer", classNameIconTooltip)} />
                </Tooltip>
            }
        </div>
    )
};
