import { Provider } from '@ethersproject/providers';
import contracts from 'config/constants/contracts';
import { getPoolProposalConfig } from 'config/constants/shareholder-book';
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from 'utils/addressHelpers';
import { getObjectDuplidate } from 'utils/mereArr';
import { AppDispatch, AppState } from '../index';
import { fectAdminChairperson, fetchDataApproving, fetchDataInfoStocks, fetchDataStock, fetchStatistics } from './actions';
import { getDataApiHistory, getDataApprover, getDataApproving, getDataStatistics, getDataStock, getInfoStocks, getTxHash, getblockToTimestamp } from './fetchDataStocks';
import { DataHistoryType } from './type';

export const GetDataChairperson = () => {
    const dataShareHolders = useSelector<AppState, AppState['storeChairperson']>((state) => state?.storeChairperson)
    return dataShareHolders
}

export const UseDataApprover = (chainId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        (async () => {
            const config = await getPoolProposalConfig(chainId)
            const dataApprovers = await getDataApprover(config, chainId)
            dispatch(fectAdminChairperson(dataApprovers))
        })();
    }, [chainId, dispatch])
};

export const UseDataApproving = (chainId: number, account: string, txHash: string) => {
    const dispatch = useDispatch<AppDispatch>();
    useSlowRefreshEffect(() => {
        (async () => {
            const contractMarketplace = getAddress(contracts.shareholderBook, chainId)
            const config = await getPoolProposalConfig(chainId)
            const dataSymbol = await getInfoStocks(config, chainId, account)
            const dataApproving = await getDataApproving(dataSymbol?.infoStocks, contractMarketplace, chainId)
            const orderId = dataApproving?.dataApproving.map((dt) => dt?.orderId)
            if (orderId?.length > 0) {
                try {
                    const dataTx = await getTxHash(orderId, chainId)
                    const mergeData = dataApproving?.dataApproving?.map((dt) => {
                        const findTx = dataTx?.validateOrders?.find((item) => item?.orderId === dt?.orderId)
                        return {
                            ...dt,
                            tradingCode: findTx?.txHash,
                        }
                    })
                    dispatch(fetchDataApproving({ dataApproving: mergeData }))
                } catch (error) {
                    const mergeData = dataApproving?.dataApproving?.map((dt) => {
                        return {
                            ...dt,
                            tradingCode: "",
                        }
                    })
                    dispatch(fetchDataApproving({ dataApproving: mergeData }))
                }
            } else {
                dispatch(fetchDataApproving({ dataApproving: [] }))
            }
            dispatch(fetchDataInfoStocks(dataSymbol))
        })();
    }, [account, chainId, dispatch, txHash])
};

export const UseDataStocks = (chainId: number, stocksAddress: string) => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        (async () => {
            if (stocksAddress?.length > 0) {
                const data = await getDataStock(stocksAddress, chainId)
                dispatch(fetchDataStock(data))
            } else {
                dispatch(fetchDataStock({ stock: null }))
            }
        })();
    }, [chainId, dispatch, stocksAddress])
};

export const UseDataStatistics = (chainId: number, account: string, txHash: string, provider: Provider | null) => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        (async () => {
            const contractMarketplace = getAddress(contracts.shareholderBook, chainId)
            const config = await getPoolProposalConfig(chainId)
            const dataSymbol = await getInfoStocks(config, chainId, account)
            const statisticsData = await getDataStatistics(dataSymbol?.infoStocks, contractMarketplace, chainId)
            try {
                const dataHistory = await getDataApiHistory(account, provider)
                const arr: DataHistoryType[] = []
                const { dataStatistics } = statisticsData
                for (let index = 0; index < dataStatistics.length; index++) {
                    const findItem = dataHistory?.dataApiHistory?.find((item) => item?.orderId === dataStatistics[index]?.orderId)
                    // eslint-disable-next-line no-await-in-loop
                    const dataTime = await getblockToTimestamp(findItem?.createTime, provider)
                    arr.push({
                        ...dataStatistics[index],
                        tx: findItem?.tx?.toString(),
                        createTime: dataTime?.currentTime,
                        month: dataTime?.month,
                        year: dataTime?.year
                    })
                }
                const resultMerge = getObjectDuplidate(arr)
                dispatch(fetchStatistics({ statistics: resultMerge }))
            } catch (error) {
                const mergeData = statisticsData?.dataStatistics?.map((_) => {
                    return {
                        nftAddress: "",
                        month: 0,
                        year: 0,
                        orderType: 0,
                        symbol: "",
                        quantity: 0
                    }
                })
                dispatch(fetchStatistics({ statistics: mergeData }))
                console.log(error)
            }
        })();
    }, [account, chainId, dispatch, provider, txHash])
};
