import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.input};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};
    border-radius: 10px;
  }
  .ant-checkbox-inner{
    width: 20px !important;
    height: 20px !important;
    &::after{
        left: 5px !important;
    }
  }
  .ant-table-thead{
        .ant-checkbox-checked .ant-checkbox-inner:after{
            left: 5px !important;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner:after{
            left: 9px !important;
        }
  }
    .ant-pagination,.ant-table-pagination{
            display: flex;
            justify-content: center;
            gap: 8px;
            li{
                margin: 0 !important;
                border-radius: 12px !important;
                @media (max-width: 1024px) {
                    border-radius: 6px !important;
                }
                border: 1px solid #E4E4E4;
            }
            .ant-pagination-jump-next{
                width: 44px !important;
                height: 44px !important;
                justify-content: center;
                align-items: center;
                display: flex;
                @media (max-width: 1024px) {
                    width: 33px !important;
                    height: 33px !important;
                }
                .ant-pagination-item-ellipsis{
                    left:  -4px !important;
                }
            }
            .ant-pagination-item,.ant-pagination-next,.ant-pagination-prev{
                border: 1px solid #E4E4E4;
                width: 44px !important;
                height: 44px !important;
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                @media (max-width: 1024px) {
                    width: 33px !important;
                    height: 33px !important;
                }
            }
            .ant-pagination-item:hover{
                background: #355DFF;
                background-color: #355DFF !important;
                border: 1px solid #355DFF;
                a{
                    color: #ffff;
                }
            }
            .ant-pagination-item-active{
                    background: #355DFF;
                    border: 1px solid #355DFF;
                a{
                    color: #ffff;
                }
            }
    }

    .globals__wrapper-scroll::-webkit-scrollbar-track{
        display: none;
    }
    .globals__wrapper-scroll::-webkit-scrollbar {
        width: 8px;
    }
    .globals__wrapper-scroll::-webkit-scrollbar-thumb {
        background: #355DFF;
        transition: all 0.4s ease-in-out;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
        background: #355DFF;
        transition: all 0.4s ease-in-out;
    }
    .globals__wrapper-scroll,.ant-table-body {
        transition: 0.3s ease-in-out;
        overflow-y: overlay;
        overflow-x: hidden;
        place-content: start
    }
    .rc-virtual-list-scrollbar-thumb{
        background: #355DFF !important;
    }
    .ant-picker{
        .ant-picker-input{
            input{
                font-size: 14px !important;
                color: #23262F !important;;
                font-weight: 700;
                @media screen and (max-width:768px){
                    font-size: 13px !important;
                }
            }
        }
    }
    .ant-form-item-label {
        label{
            color: #B1B5C3 !important;
            text-transform: uppercase;
            font-size: 14px !important;
            font-weight: 700 !important;
                @media screen and (max-width:768px){
                    font-size: 13px !important;
                }
            &::before{
                display: none !important;
            }
        }
    }
    .ant-picker-ok{
        button{
            background-color: #1677ff !important;
        }
    }
    .ant-input-number-input-wrap{
        width: 100%;
    }
    .ant-input-number-group-addon{
        border: none !important;
        background-color: transparent !important;
    }
    .ant-input-number-group{
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        &:hover, &:focus{
            border-color: #4096ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
        }
    }
    .ant-select-selection-item{
        display: flex;
    }
    .ant-form-item-explain-error{
        top: 5px;
        position: relative;
    }
    .ant-input-number-group-wrapper-status-error{
        .ant-input-number-group{
            border: 1px solid red;
            border-radius: 6px;
        }
    }
    .ant-notification-notice-message{
        color: #23262F;
        font-size: 16px;
        font-weight: 700;
    }
    .ant-picker-cell-disabled{
        &::before{
            top: 35% !important;
        }
    }
    .ant-form-item-has-error{
        margin-bottom: 35px !important;
    }
    .ant-remove-ok .ant-picker-ok{
        display: none !important;
    }
    .ant-select-item-option-selected {
        .ant-select-item-option-content{
            .ant-space-item{
                color: #355DFF;
            }
        }
    }
`

export default GlobalStyle
