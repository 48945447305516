import { createReducer } from '@reduxjs/toolkit';
import { ChairpersonType, ListApproverType } from 'type';
import {
    actionGetDataChairperson, actionGetHistoryApprovers, fectAdminChairperson, fetchDataApproving, fetchDataInfoStocks,
    fetchDataStock, fetchStatistics,
    fetchTxHash
} from './actions';
import { AdminChairperson, ApprovingItem, InfoStocksType, StatisticsType, StockItem } from './type';

interface global {
    loading: boolean;
    data: ChairpersonType[],
    listApprover: AdminChairperson[],
    dataApproving: ApprovingItem[],
    infoStocks: InfoStocksType[],
    txHash: string,
    stock: StockItem,
    statistics: StatisticsType[],
    historyApprovers: ListApproverType[]
}
export const initialState: global = {
    loading: false,
    data: [],
    listApprover: [],
    dataApproving: [],
    infoStocks: [],
    txHash: "",
    stock: null,
    statistics: [],
    historyApprovers: [],
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(actionGetDataChairperson, (state, action) => {
            state.data = action.payload
        })
        .addCase(fectAdminChairperson, (state, action) => {
            state.listApprover = action.payload.listApprover
        })
        .addCase(fetchDataApproving, (state, action) => {
            state.dataApproving = action.payload.dataApproving
        })
        .addCase(fetchDataInfoStocks, (state, action) => {
            state.infoStocks = action.payload.infoStocks
        })
        .addCase(fetchTxHash, (state, action) => {
            state.txHash = action.payload.txHash
        })
        .addCase(fetchDataStock, (state, action) => {
            state.stock = action.payload.stock
        })
        .addCase(fetchStatistics, (state, action) => {
            state.statistics = action.payload.statistics
        })
        .addCase(actionGetHistoryApprovers, (state, action) => {
            state.historyApprovers = action.payload
        })
)
