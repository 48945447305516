
export const IconTrade = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3333 7.08341C18.3333 10.0751 15.9083 12.5001 12.9167 12.5001C12.775 12.5001 12.625 12.4918 12.4833 12.4834C12.275 9.84176 10.1583 7.72507 7.51666 7.51674C7.50832 7.37507 7.5 7.22508 7.5 7.08341C7.5 4.09175 9.925 1.66675 12.9167 1.66675C15.9083 1.66675 18.3333 4.09175 18.3333 7.08341Z" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.4993 12.9167C12.4993 15.9083 10.0743 18.3333 7.08268 18.3333C4.09102 18.3333 1.66602 15.9083 1.66602 12.9167C1.66602 9.925 4.09102 7.5 7.08268 7.5C7.22435 7.5 7.37434 7.50832 7.51601 7.51666C10.1577 7.72499 12.2744 9.84168 12.4827 12.4833C12.491 12.625 12.4993 12.775 12.4993 12.9167Z" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.65768 1.66675H2.49935C2.04102 1.66675 1.66602 2.04175 1.66602 2.50008V4.65841C1.66602 5.40008 2.56601 5.77507 3.09101 5.25007L5.24934 3.09174C5.76601 2.56674 5.39935 1.66675 4.65768 1.66675Z" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.3408 18.3332H17.4991C17.9574 18.3332 18.3324 17.9582 18.3324 17.4999V15.3416C18.3324 14.5999 17.4325 14.2249 16.9075 14.7499L14.7491 16.9082C14.2325 17.4332 14.5991 18.3332 15.3408 18.3332Z" stroke="#23262F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
