import * as React from 'react';
import { storeInfoOrder, useAppDispatch, useAppSelector } from 'state';
import { onGetApiOrder } from 'state/state-info-order';
import { InfoOrderType, ParamsGetApiOrder } from 'type';
import { STATUS_TYPE, checkDefaultStatus } from 'config';
import { useAccount } from './use-account';


const dataOptionStocksStatus = [
    { value: "All", label: "Tất cả" },
    { value: checkDefaultStatus(STATUS_TYPE.PENDING), label: checkDefaultStatus(STATUS_TYPE.PENDING) },
    { value: checkDefaultStatus(STATUS_TYPE.CREATED), label: checkDefaultStatus(STATUS_TYPE.CREATED) },
    { value: checkDefaultStatus(STATUS_TYPE.PAUSED), label: checkDefaultStatus(STATUS_TYPE.PAUSED) },
]

export const useInfoOrder = () => {
    const dispatch = useAppDispatch();
    const storeOrder = useAppSelector(storeInfoOrder);
    const { account } = useAccount();

    const handleGetApiOrder = React.useCallback((params: ParamsGetApiOrder) => account && dispatch(onGetApiOrder(params)), [account, dispatch])

    const dataOptionStocksCode = React.useMemo(() => {
        return storeOrder.data.reduce((acc, item: InfoOrderType) => {
            const index = acc.findIndex(i => i?.value === item.stocks_code);
            if (index === -1) {
                acc.push({
                    value: item.stocks_code,
                    label: item.stocks_code
                })
            }
            return acc;
        }, [])
    }, [storeOrder.data]);


    return {
        storeOrder,
        dataOptionStocksStatus,
        dataOptionStocksCode,
        handleGetApiOrder
    }
}
