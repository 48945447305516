import { Text, Flex, Button, ModalV2 } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
    width: 100%;
    height: 66vh;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding-right: 5px;
    padding: 1rem;
    @media screen and (max-width: 600px) {
        min-height: 10vh;
        height: fit-content;
        overflow-y: auto;
    }
`
export const Row = styled(Flex)`
    width: 100%;
    height: 64px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.colors.text};
    border-radius: 0px;
    margin-bottom: 1rem;
    padding-left: 1rem;
    gap: 1rem;
    &:hover{
        background: #355DFF;
        ${Text}{
            color:${({ theme }) => theme.colors.white};
        }
    }
`
export const CsModalV2 = styled(ModalV2)`
    z-index: 99999 !important;
`
