import { createSlice, ActionReducerMapBuilder, } from '@reduxjs/toolkit';
import { InfoHistoryType, ParamsGetApiHistory } from 'type';
import { onGetApiHistory } from './patch-api';

interface InitialStateType {
    data: InfoHistoryType[];
    loading: boolean;
    paramsUrl: ParamsGetApiHistory,
    total: number;
    total_quantity: number;
}
const initialState: InitialStateType = {
    data: [],
    loading: true,
    paramsUrl: {} as ParamsGetApiHistory,
    total: 0,
    total_quantity: 0,
}

const historySlice = createSlice({
    name: "historySlice",
    initialState,
    reducers: {
        onDisconnectWalletHistory: (state, action) => {
            state.data = action.payload;
        },
        onLoadingWalletHistory: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder
            .addCase(onGetApiHistory.pending, (state: InitialStateType) => {
                state.loading = true;
            })
            .addCase(onGetApiHistory.fulfilled, (state: InitialStateType, action) => {
                state.paramsUrl = action.meta.arg;
                state.loading = false;
                state.data = action.payload.data;
                state.total = action.payload.total;
                state.total_quantity = action.payload.total_quantity
            })
            .addCase(onGetApiHistory.rejected, (state: InitialStateType) => {
                state.loading = false;
            })
    }
});

const { reducer, actions } = historySlice;
export const { onDisconnectWalletHistory, onLoadingWalletHistory } = actions;
export default reducer;
