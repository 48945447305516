import React, { useContext } from "react";
import { MenuContext } from "../../widgets/Menu/context";
import { Flex } from "../Box";
import AnimatedIconComponent from "../Svg/AnimatedIconComponent";
import { StyledBottomNavItem, StyledBottomNavText } from "./styles";
import { BottomNavItemProps } from "./types";

const BottomNavItem: React.FC<React.PropsWithChildren<BottomNavItemProps>> = ({
    label,
    icon,
    fillIcon,
    href,
    showItemsOnMobile = false,
    isActive = false,
    disabled = false,
    ...props
}) => {
    const { linkComponent } = useContext(MenuContext);
    const bottomNavItemContent = (
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            {icon && (
                <AnimatedIconComponent
                    icon={icon}
                    fillIcon={fillIcon}
                    height="22px"
                    width="21px"
                    color={isActive ? "secondary" : "textSubtle"}
                    isActive={isActive}
                    activeBackgroundColor="background"
                />
            )}
            <StyledBottomNavText
                color={isActive ? "text" : "text"}
                fontWeight={isActive ? "600" : "400"}
                fontSize="9px"
                textTransform="uppercase"
                isActive={isActive}
                marginTop="4px"
            >
                {label}
            </StyledBottomNavText>
        </Flex>
    );

    return showItemsOnMobile ? (
        <StyledBottomNavItem style={{ opacity: disabled ? 0.5 : 1 }} type="button" {...props}>
            {bottomNavItemContent}
        </StyledBottomNavItem>
    ) : (
        <StyledBottomNavItem style={{ opacity: disabled ? 0.5 : 1 }} as={linkComponent} href={href} {...props}>
            {bottomNavItemContent}
        </StyledBottomNavItem>
    );
};

export default BottomNavItem;
