import * as React from 'react';

import { storeInfoHistory, useAppDispatch, useAppSelector } from 'state';
import { onGetApiHistory, onGetApiHistoryDownload } from 'state/state-info-history';
import { onDisconnectWalletHistory, onLoadingWalletHistory } from 'state/state-info-history/history-slice';
import { InfoHistoryType, ParamsGetApiHistory } from 'type';
import useActiveWeb3React from './useActiveWeb3React';



export const useInfoHistory = () => {
    const { account } = useActiveWeb3React();
    const dispatch = useAppDispatch();
    const storeHistory = useAppSelector(storeInfoHistory);

    const handleGetApiHistory = React.useCallback((params: ParamsGetApiHistory) => account && dispatch(onGetApiHistory(params)), [account, dispatch]);
    const handleGetApiHistoryDownload = React.useCallback((params: ParamsGetApiHistory) => account && dispatch(onGetApiHistoryDownload(params)), [account, dispatch]);
    const handleDisconnectWalletHistory = React.useCallback((payload) => dispatch(onDisconnectWalletHistory(payload)), [dispatch]);
    const handleLoadingWalletHistory = React.useCallback((payload: boolean) => dispatch(onLoadingWalletHistory(payload)), [dispatch]);

    const dataOptionStocksCodeHistory = React.useMemo(() => {
        return storeHistory.data.reduce((acc, item: InfoHistoryType) => {
            const index = acc.findIndex(i => i.label === item.stocks_code);
            if (index === -1) {
                acc.push({
                    value: item.nft_address,
                    label: item.stocks_code,
                })
            }
            return acc;
        }, [{ value: "ALL", label: "Tất cả" }])
    }, [storeHistory.data]);

    return {
        storeHistory,
        dataOptionStocksCodeHistory,
        handleGetApiHistory,
        handleLoadingWalletHistory,
        handleDisconnectWalletHistory,
        handleGetApiHistoryDownload
    }
}
