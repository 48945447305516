import BigNumber from "bignumber.js";
import dayjs from "dayjs";

import multicall from "utils/multicall";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { ORDER_TYPE, STATUS_TYPE, checkOrderType, checkDefaultStatus } from "config";
import { GetOrderAsListType } from "type";
import { getStockSymbol } from "state/state-info-asset";
import nftShareholderBookAbi from 'config/abi/nftShareholderBookAbi.json';

export const getDataOrder = async (marketplaceAddress: string, account: string, chainId: number): Promise<GetOrderAsListType[]> => {
    try {
        const resultGetOrderAsList = await getOrderAsList(marketplaceAddress, chainId, account);
        return resultGetOrderAsList;
    } catch (error) {
        console.log('error', error)
        return [];
    }
};


export const getOrderAsListStockCode = async (marketplaceAddress: string, chainId: number, account: string): Promise<GetOrderAsListType[]> => {
    try {
        const call = [{ address: marketplaceAddress, name: "getOrderAsList", params: [account] }];
        const [result] = await multicall(marketplaceAbi, call, chainId);
        const payload = result[0].map((item) => {

            const quantity = new BigNumber(item.quantity.toNumber()).toNumber();
            const unitPrice = new BigNumber(item.unitPrice.toNumber()).toNumber();
            const matchedQuantity = new BigNumber(item.matchedQuantity.toNumber()).toNumber();
            const originalQuantity = new BigNumber(item.originalQuantity.toNumber()).toNumber();
            const cancelQuantity = new BigNumber(originalQuantity).minus(quantity).toNumber();
            const remainingQuantity = (new BigNumber(originalQuantity).minus(cancelQuantity)).minus(matchedQuantity).toNumber();

            return {
                create_time: new BigNumber(item.createTimestamp.toNumber()).toNumber() * 1000,
                end_time: checkEndTime(item),
                transaction_quantity: matchedQuantity,
                nft_address: item.nftAddress,
                order_creator: item.orderCreator,
                order_id: new BigNumber(item.orderId.toNumber()).toNumber(),
                original_quantity: originalQuantity,
                order_type: item.orderType,
                order_code: checkOrderType(item.orderType),
                quantity,
                receiver: item.receiver,
                status: item.status,
                target_order_id: new BigNumber(item.targetOrderId.toNumber()).toNumber(),
                token_id: new BigNumber(item.tokenId.toNumber()).toNumber(),
                unit_price: unitPrice,
                total_value: new BigNumber(remainingQuantity).multipliedBy(unitPrice).toNumber(),
                remaining_quantity: remainingQuantity,
                cancel_quantity: cancelQuantity
            }
        });

        const checkNftDuplicate = payload.reduce((acc, pre) => {
            const index = acc.findIndex(i => i.nft_address.toLowerCase() === pre.nft_address.toLowerCase());
            if (index === -1) {
                acc.push(pre)
            }
            return acc;
        }, []);

        const getDataAccount = await Promise.all(checkNftDuplicate.map(async (item) => {
            const stocks_code = await getStockSymbol(item.nft_address, chainId);
            const paused = await getPaused(item.nft_address, chainId);
            const isManual = await getIsManual(item.nft_address, chainId);
            return {
                ...item,
                ...isManual,
                status: paused.paused ? checkStatusPause(item.status) : item.status,
                status_code: checkDefaultStatus(paused.paused ? checkStatusPause(item.status) : item.status),
                ...stocks_code,
                ...paused,
                is_expired: checkIsExpired(item),
            }
        }));

        return getDataAccount;

    } catch (error) {
        console.log('getOrderAsList error', error)
        return [];
    }
};

const getOrderAsList = async (marketplaceAddress: string, chainId: number, account: string): Promise<GetOrderAsListType[]> => {
    try {
        const call = [{ address: marketplaceAddress, name: "getOrderAsList", params: [account] }];
        const [result] = await multicall(marketplaceAbi, call, chainId);
        const payload = result[0].map((item) => {

            const quantity = new BigNumber(item.quantity.toNumber()).toNumber();
            const unitPrice = new BigNumber(item.unitPrice.toNumber()).toNumber();
            const matchedQuantity = new BigNumber(item.matchedQuantity.toNumber()).toNumber();
            const originalQuantity = new BigNumber(item.originalQuantity.toNumber()).toNumber();
            const cancelQuantity = new BigNumber(originalQuantity).minus(quantity).toNumber();
            const remainingQuantity = (new BigNumber(originalQuantity).minus(cancelQuantity)).minus(matchedQuantity).toNumber();

            return {
                create_time: new BigNumber(item.createTimestamp.toNumber()).toNumber() * 1000,
                end_time: checkEndTime(item),
                transaction_quantity: matchedQuantity,
                nft_address: item.nftAddress,
                order_creator: item.orderCreator,
                order_id: new BigNumber(item.orderId.toNumber()).toNumber(),
                original_quantity: originalQuantity,
                order_type: item.orderType,
                order_code: checkOrderType(item.orderType),
                quantity,
                receiver: item.receiver,
                status: item.status,
                target_order_id: new BigNumber(item.targetOrderId.toNumber()).toNumber(),
                token_id: new BigNumber(item.tokenId.toNumber()).toNumber(),
                unit_price: unitPrice,
                total_value: new BigNumber(remainingQuantity).multipliedBy(unitPrice).toNumber(),
                remaining_quantity: remainingQuantity,
                cancel_quantity: cancelQuantity
            }
        });
        const filterAccount = payload.filter((i) => i.quantity > 0 && i.remaining_quantity > 0);
        const getDataAccount = await Promise.all(filterAccount.map(async (item) => {
            const stocks_code = await getStockSymbol(item.nft_address, chainId);
            const paused = await getPaused(item.nft_address, chainId);
            const isManual = await getIsManual(item.nft_address, chainId);
            return {
                ...item,
                ...isManual,
                status: paused.paused ? checkStatusPause(item.status) : item.status,
                status_code: checkDefaultStatus(paused.paused ? checkStatusPause(item.status) : item.status),
                ...stocks_code,
                ...paused,
                is_expired: checkIsExpired(item),
            }
        }));

        const sortedTime = getDataAccount.map(i => ({ ...i })).sort((a, b) => {
            return b.create_time - a.create_time;
        });

        return sortedTime.filter(i =>
            i.status === STATUS_TYPE.PAUSED
            || i.status === STATUS_TYPE.PENDING
            || i.status === STATUS_TYPE.CREATED
        );

    } catch (error) {
        console.log('getOrderAsList error', error)
        return [];
    }
};

export const getPaused = async (address: string, chainId: number): Promise<{ paused: boolean }> => {
    try {
        const call = [{ address, name: "paused", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            paused: result[0]
        }
    } catch (error) {
        console.log('paused error:', error)
        return {
            paused: false,
        }
    }
};

export const getIsManual = async (address: string, chainId: number): Promise<{ is_manual: boolean }> => {
    try {
        const call = [{ address, name: "isManual", params: [] }]
        const [result] = await multicall(nftShareholderBookAbi, call, chainId);
        return {
            is_manual: result[0]
        }
    } catch (error) {
        console.log('isManual error:', error)
        return {
            is_manual: false,
        }
    }
}

const checkStatusPause = (status: number) => {
    if (status === STATUS_TYPE.PENDING || status === STATUS_TYPE.CREATED) {
        return STATUS_TYPE.PAUSED;
    }
    return status;
};


const checkEndTime = (item) => {
    if (item.orderType === ORDER_TYPE.BUY || item.orderType === ORDER_TYPE.SELL) {
        return new BigNumber(item.endTimestamp.toNumber()).toNumber() * 1000;
    }
    return new BigNumber(item.createTimestamp.toNumber()).toNumber() * 1000;
};

const checkIsExpired = (item) => {
    if (item.order_type === ORDER_TYPE.BUY || item.order_type === ORDER_TYPE.SELL) {
        return item.end_time < dayjs().valueOf() && !item.paused
    }
    return false
};
