import BigNumber from "bignumber.js";

import multicall from "utils/multicall";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { TransactionType } from "type";
import { STATUS_TYPE, checkOrderType, checkDefaultStatus } from "config";
import { getCompanyName, getIssuePrice, getStockSymbol, } from "state/state-info-asset";
import dayjs from "dayjs";
import { getIsManual, getPaused } from "state/state-info-order/fetch-data";

export const getDataTransaction = async (marketplaceAddress: string, chainId: number): Promise<TransactionType[]> => {
    try {
        const totalOrder = await getTotalOrder(marketplaceAddress, chainId);
        if (totalOrder > 1) {
            const result = await Promise.all([...Array(totalOrder)].map(async (_, index) => {
                const listOrders = await getOrders(marketplaceAddress, index + 1, chainId);
                return {
                    ...listOrders,
                }
            }));
            return result.filter((item: TransactionType) =>
                item.status === STATUS_TYPE.CREATED
                && item.end_time > dayjs().valueOf()
                && item.quantity > 0 && !item.paused
            )
        }
        return [];
    } catch (error) {
        return [];
    }
};

const getTotalOrder = async (marketplaceAddress: string, chainId: number): Promise<number> => {
    try {
        const call = [{ address: marketplaceAddress, name: "totalOrder", params: [] }];
        const [resultTotalOrder] = await multicall(marketplaceAbi, call, chainId);
        const result = new BigNumber(resultTotalOrder).toNumber();
        return result;
    } catch (error) {
        console.log('error totalOrder', error)
        return 0
    }
};

const getOrders = async (marketplaceAddress: string, index: number, chainId: number): Promise<TransactionType> => {
    try {
        const call = [{ address: marketplaceAddress, name: "orders", params: [index] }];
        const [result] = await multicall(marketplaceAbi, call, chainId);
        const payload = {
            order_id: new BigNumber(result.orderId.toNumber()).toNumber(),
            order_creator: result.orderCreator,
            nft_address: result.nftAddress,
            token_id: new BigNumber(result.tokenId.toNumber()).toNumber(),
            unit_price: new BigNumber(result.unitPrice.toNumber()).toNumber(),
            quantity: new BigNumber(new BigNumber(result.quantity.toNumber()).toNumber()).minus(new BigNumber(result.matchedQuantity.toNumber()).toNumber()).toNumber(),
            matched_quantity: new BigNumber(result.matchedQuantity.toNumber()).toNumber(),
            create_time: new BigNumber(result.createTimestamp.toNumber()).toNumber() * 1000,
            end_time: new BigNumber(result.endTimestamp.toNumber()).toNumber() * 1000,
            status: result.status,
            status_code: checkDefaultStatus(result.status),
            order_type: result.orderType,
            order_code: checkOrderType(result.orderType),
        };
        if (payload.create_time > 0) {
            const stocks_code = await getStockSymbol(payload.nft_address, chainId);
            const price = await getIssuePrice(payload.nft_address, chainId);
            const company = await getCompanyName(payload.nft_address, chainId);
            const paused = await getPaused(payload.nft_address, chainId);
            const isManual = await getIsManual(payload.nft_address, chainId);
            return {
                ...isManual,
                ...payload,
                ...stocks_code,
                ...price,
                ...company,
                ...paused,
                status: paused.paused ? STATUS_TYPE.PAUSED : result.status,
                status_code: checkDefaultStatus(paused.paused ? STATUS_TYPE.PAUSED : result.status),
            };
        }
        return {} as TransactionType;
    } catch (error) {
        console.log('error orders:', error)
        return {} as TransactionType;
    }
};
