import clsx from 'clsx';

interface TitleSectionType {
    title: string;
    className?: string;
}

export const TitleSection = ({
    title,
    className = "sm:text-3xl sm:w-auto w-full text-left text-lg"
}: TitleSectionType) => {
    return (
        <h6 className={clsx("text-[#23262F] font-bold", className)}>{title}</h6>
    )
}
