import { DatePicker, Form, InputNumber, Select, Spin, Tooltip } from "antd";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import * as React from "react";

import { Button, CalendarV2Icon } from '@pancakeswap/uikit';
import { ORDER_TYPE, PAYMENT, formatNumberPayment } from "config";
import { CreateBuyOrderType, useAccount, useActiveWeb3React, useInfoAsset, useModal, useTransaction } from "hooks";
import { InfoAssetType } from "type";

import { EquivalentPayment, NoData, WrapperItemForm, WrapperScroll } from "components";
import { useStateInfoAsset } from "state/state-info-asset";
import { ItemInfo, WrapperItemInfo } from "../item-info";
import { Note } from "../note";

interface DataOptionSelectType {
    value: string;
    label: string;
    token_id: number;
    nft_address: string;
    order_type: number;
    paused: boolean;
    is_manual: boolean;
    price: number;
}

interface FormType {
    price: number;
    quantity: number;
    share: string;
    date: any;
}
export const ModalCreateBuyOrder = () => {
    const [form] = Form.useForm();
    useStateInfoAsset();

    const { account } = useActiveWeb3React();
    const { handleCloseModal } = useModal();
    const { storeAccount } = useAccount();
    const { loadingTx, handleCreateBuyOrder, handleApprovalAllowance } = useTransaction();
    const { storeAsset } = useInfoAsset();

    const [price, setPrice] = React.useState<number>(0);
    const [quantity, setQuantity] = React.useState<number>(0);
    const [dataAsset, setDataAsset] = React.useState<InfoAssetType>();
    const [dataSelect, setDataSelect] = React.useState<DataOptionSelectType>();

    const totalPrice = React.useMemo(() => new BigNumber(price || 0).multipliedBy(quantity || 0).toNumber(), [price, quantity]);

    const isApprovalAllowance = React.useMemo(() => {
        return !new BigNumber(storeAccount.data.allowance).isGreaterThan(totalPrice);
    }, [storeAccount.data.allowance, totalPrice]);

    const isTotalMoneyOfBalance = React.useMemo(() => {
        return new BigNumber(totalPrice).isGreaterThan(storeAccount.data.balance);
    }, [storeAccount.data.balance, totalPrice]);

    const dataOptionSelect: DataOptionSelectType[] = React.useMemo(() => {
        return storeAsset.dataCreateBuyOrder.reduce((acc: DataOptionSelectType[], item: InfoAssetType) => {
            const index = acc.findIndex(i => i?.value === item.stocks_code && i?.nft_address === item.nft_address);
            if (index === -1) {
                acc.push({
                    value: item.stocks_code,
                    label: item.company,
                    token_id: item.id,
                    nft_address: item.nft_address,
                    order_type: ORDER_TYPE.BUY,
                    paused: item.paused,
                    is_manual: item.is_manual,
                    price: item.price
                })
            }
            return acc;
        }, []);
    }, [storeAsset.dataCreateBuyOrder]);

    const onSubmit = async (value: FormType) => {
        if (account) {
            const message = dataSelect.is_manual ? "Tạo lệnh mua thành công" : "Tạo lệnh mua thành công";
            const description = `${formatNumberPayment(value.quantity)} cổ phiếu mã ${dataSelect.value} với giá ${formatNumberPayment(value.price)} ${PAYMENT}`;
            const date = Math.floor(value.date.valueOf() / 1000);
            const payload: CreateBuyOrderType = [dataSelect.nft_address, dataSelect.token_id, value.price, value.quantity, date, dataSelect.order_type];
            if (isApprovalAllowance) {
                handleApprovalAllowance();
            } else {
                handleCreateBuyOrder(payload, message, description);
            }
        }
    };

    const handleOnChangeSelect = (stocksCode: string) => {
        const itemAsset = storeAsset.data.find((items: InfoAssetType) => items.stocks_code === stocksCode);
        const itemSelect = dataOptionSelect.find((items: DataOptionSelectType) => items.value === stocksCode);
        setDataAsset(itemAsset);
        setDataSelect(itemSelect);
        form.resetFields(["price", "quantity", "date"]);
        setQuantity(0);
        setPrice(0);
    };

    React.useEffect(() => {
        if (dataOptionSelect.length) {
            const first: DataOptionSelectType = dataOptionSelect[0];
            const item = storeAsset.data.find((items: InfoAssetType) => items.stocks_code === first.value);
            if (!dataSelect && !dataAsset) {
                setDataSelect(first);
                setDataAsset(item);
                form.setFieldsValue({
                    share: first.value,
                })
            }
        }
    }, [dataAsset, dataOptionSelect, dataSelect, form, storeAsset.data]);

    if (storeAsset.loading) {
        return (
            <div className="flex items-center justify-center min-h-[20vh]">
                <Spin />
            </div>
        )
    }
    if (!dataOptionSelect.length || !account || !dataSelect) {
        return (
            <NoData />
        );
    }
    const disabledHours = () => {
        const hours = [];
        const currentHour = dayjs().hour();

        for (let i = currentHour - 1; i >= 0; i--) {
            hours.push(i);
        }

        return hours;
    };

    const disabledMinutes = (selectedHour) => {
        const minutes = [];
        const currentMinute = dayjs().minute();
        if (selectedHour === dayjs().hour()) {
            for (let i = currentMinute; i >= 0; i--) {
                minutes.push(i);
            }
        }
        return minutes;
    };
    return (
        <WrapperScroll className="max-h-[80vh]">
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
                scrollToFirstError
            >
                <Form.Item
                    label="Chọn mã cổ phiếu"
                    name="share"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng chọn mã cổ phiếu"
                        }
                    ]}
                >
                    <Select
                        showSearch
                        disabled={loadingTx}
                        placeholder="Mã cổ phiếu!"
                        optionLabelProp="label"
                        className="w-full"
                        size="large"
                        onChange={handleOnChangeSelect}
                        notFoundContent={<NoData />}
                    >
                        {
                            dataOptionSelect.map((item) => (
                                <Select.Option
                                    value={item.value}
                                    label={
                                        <Tooltip title={item.label?.length > 50 && item.label}>
                                            <div className="flex items-center gap-2 truncate">
                                                <p className="text-[#23262F] text-sm font-bold">{item.value}</p>
                                                <p className="text-[#777E90] text-sm font-bold truncate">{item.label}</p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Tooltip title={item.label?.length > 50 && item.label}>
                                        <div className="flex gap-2 truncate">
                                            <p>{item.value}</p>
                                            <p className="truncate">{item.label}</p>
                                        </div>
                                    </Tooltip>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <WrapperItemInfo >
                        <ItemInfo title="Số lượng có sẵn" value={dataAsset?.quantity || 0} uti="Cổ phiếu" />
                        <ItemInfo title="Số dư khả dụng" value={storeAccount.data.balance} uti={PAYMENT} />
                        <div className="text-right">
                            <EquivalentPayment value={storeAccount.data.balance} />
                        </div>
                    </WrapperItemInfo>
                </Form.Item>
                <Form.Item
                    label="Đơn giá"
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập đơn giá"
                        }
                    ]}
                >
                    <InputNumber
                        min={1}
                        disabled={dataSelect.paused || loadingTx}
                        size="large"
                        bordered={false}
                        placeholder="0"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => setPrice(e)}
                        addonAfter={
                            <p className="text-[#B1B5C3] font-medium text-xs uppercase mr-3">{PAYMENT}</p>
                        }
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    label="Số lượng"
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập số lượng"
                        }
                    ]}
                >
                    <InputNumber
                        min={1}
                        disabled={dataSelect.paused || loadingTx}
                        size="large"
                        bordered={false}
                        placeholder="0"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => setQuantity(e)}
                        addonAfter={
                            <p className="text-[#B1B5C3] font-medium text-xs mr-2 hover:text-slate-700">Cổ phiếu</p>
                        }
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                        className="font-bold text-sm w-full flex flex-row-reverse"
                        controls={false}
                    />
                </Form.Item>
                <WrapperItemForm
                    title="Thành tiền"
                    className="mb-6"
                >
                    <div>
                        <InputNumber
                            disabled={dataSelect.paused || loadingTx}
                            status={isTotalMoneyOfBalance ? "error" : ""}
                            max={storeAccount.data.balance}
                            value={totalPrice}
                            readOnly
                            size="large"
                            bordered={false}
                            placeholder="0"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            addonAfter={
                                <p className="text-[#B1B5C3] font-medium text-xs mr-2 hover:text-slate-700">{PAYMENT}</p>
                            }
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            className="font-bold text-sm w-full flex flex-row-reverse"
                            controls={false}
                        />
                        <div className="text-right mt-2">
                            <EquivalentPayment value={totalPrice} />
                        </div>
                        {isTotalMoneyOfBalance && <p className="text-[#FB1919] text-sm font-medium mt-2">Số tiền vượt quá số dư khả dụng</p>}
                    </div>
                </WrapperItemForm>
                <Form.Item
                    label="Ngày hết hạn"
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng đặt ngày hết hạn"
                        }
                    ]}
                >
                    <DatePicker
                        inputReadOnly
                        size="large"
                        showTime={{ format: 'HH:mm', }}
                        showNow={false}
                        showToday={false}
                        popupClassName="ant-remove-ok"
                        disabled={dataSelect.paused || loadingTx}
                        className="w-full"
                        allowClear={false}
                        placeholder={dayjs().format("DD/MM/YYYY HH:mm")}
                        format="DD/MM/YYYY HH:mm"
                        disabledDate={(current) => {
                            const customDate = dayjs().format("DD/MM/YYYY HH:mm");
                            return current && current < dayjs(customDate, "DD/MM/YYYY HH:mm");
                        }}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        onSelect={e => { form.setFieldsValue({ date: e }) }}
                        suffixIcon={<CalendarV2Icon />}
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <div>
                            <div className="grid grid-cols-2 md:gap-8 gap-2 mt-4">
                                <Button
                                    style={{ border: "2px solid #E6E8EC" }}
                                    className="bg-white text-[#23262F]"
                                    onClick={handleCloseModal}
                                    disabled={loadingTx}
                                >
                                    Hủy
                                </Button>
                                <Button
                                    loading={loadingTx}
                                    disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length
                                        || loadingTx
                                        || isTotalMoneyOfBalance
                                        || dataSelect.paused
                                        || !form.isFieldsTouched(false)
                                    }
                                >
                                    {
                                        !account ? "Tạo lệnh mua" : <>
                                            {isApprovalAllowance ? "Chấp thuận" : "Tạo lệnh mua"}
                                        </>
                                    }
                                </Button>
                            </div>
                            {dataSelect.paused && <p className="text-[#EF1616] font-bold text-sm mt-4 text-center">Mã cổ phiếu đang tạm ngừng giao dịch</p>}
                        </div>
                    )}
                </Form.Item>
            </Form>
            <Note />
        </WrapperScroll >
    )
};

