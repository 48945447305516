import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { InitialStateTransactionType, TransactionType } from 'type';
import { actionGetDataTransaction, actionLoadingDataTransaction, actionTransactionHashTransaction } from './actions';

const initialState = {
    loading: true,
    data: [],
    transactionHash: null,
};

export const stateTransaction = createReducer(initialState, (builder) =>
    builder
        .addCase(actionGetDataTransaction, (state: InitialStateTransactionType, action: PayloadAction<TransactionType[]>) => {
            state.data = action.payload
        })
        .addCase(actionTransactionHashTransaction, (state: InitialStateTransactionType, action: PayloadAction<string>) => {
            state.transactionHash = action.payload;
        })
        .addCase(actionLoadingDataTransaction, (state: InitialStateTransactionType, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        })
);
