import * as React from 'react';

import { storeInfoAsset, useAppDispatch, useAppSelector } from 'state';
import { InitialStateAssetType } from 'type';
import { actionTransactionHashInfoAsset } from 'state/state-info-asset';
import { onTransactionHashOrder } from 'state/state-info-order/order-slice';
import { useCallWithMarketGasPrice } from './useCallWithMarketGasPrice';
import { useRejected } from './use-rejected';
import { useNotification } from './use-notification';
import { useModal } from './use-modal';

export const useInfoAsset = () => {
    const dispatch = useAppDispatch();
    const [loadingTx, setLoadingTx] = React.useState<boolean>(false);

    const { handleCloseModal, handlePauseCloseModal } = useModal();
    const { notification } = useNotification();
    const { handleRejected } = useRejected();

    const storeAsset: InitialStateAssetType = useAppSelector(storeInfoAsset);
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();

    const handleTransferNft = React.useCallback(async (contractNft, payload) => {
        try {
            setLoadingTx(true);
            handlePauseCloseModal(true);
            const tx = await callWithMarketGasPrice(contractNft, "safeTransferFrom", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                notification({
                    message: "Chuyển nhượng thành công",
                    txHash: receipt.transactionHash,
                });
                dispatch(actionTransactionHashInfoAsset(receipt.transactionHash));
                dispatch(onTransactionHashOrder(receipt.transactionHash));
                handleCloseModal();
            }
            setLoadingTx(false);
            handlePauseCloseModal(false);
        } catch (error) {
            setLoadingTx(false);
            handleRejected(error);
            handlePauseCloseModal(false);
        }
    }, [callWithMarketGasPrice, dispatch, handleCloseModal, handlePauseCloseModal, handleRejected, notification]);

    return {
        loadingTx,
        storeAsset,
        handleTransferNft
    }
}
