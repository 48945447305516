export const IconCopy = (props) => {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.15033 13.2705H4.93366C2.37283 13.2705 1.22949 12.1272 1.22949 9.56634V7.34967C1.22949 4.78884 2.37283 3.64551 4.93366 3.64551H6.68366C6.92283 3.64551 7.12116 3.84384 7.12116 4.08301C7.12116 4.32217 6.92283 4.52051 6.68366 4.52051H4.93366C2.84533 4.52051 2.10449 5.26134 2.10449 7.34967V9.56634C2.10449 11.6547 2.84533 12.3955 4.93366 12.3955H7.15033C9.23866 12.3955 9.97949 11.6547 9.97949 9.56634V7.81634C9.97949 7.57717 10.1778 7.37884 10.417 7.37884C10.6562 7.37884 10.8545 7.57717 10.8545 7.81634V9.56634C10.8545 12.1272 9.71116 13.2705 7.15033 13.2705Z" fill="#292D32" />
            <path d="M10.4169 8.25396H8.55026C6.91109 8.25396 6.24609 7.58896 6.24609 5.94979V4.08312C6.24609 3.90812 6.35109 3.74479 6.51443 3.68062C6.67776 3.61062 6.86443 3.65146 6.99276 3.77396L10.7261 7.50729C10.8486 7.62979 10.8894 7.82229 10.8194 7.98562C10.7553 8.14896 10.5919 8.25396 10.4169 8.25396ZM7.12109 5.13896V5.94979C7.12109 7.11062 7.38943 7.37896 8.55026 7.37896H9.36109L7.12109 5.13896Z" fill="#292D32" />
            <path d="M9.59994 1.60449H7.2666C7.02743 1.60449 6.8291 1.40616 6.8291 1.16699C6.8291 0.927826 7.02743 0.729492 7.2666 0.729492H9.59994C9.8391 0.729492 10.0374 0.927826 10.0374 1.16699C10.0374 1.40616 9.8391 1.60449 9.59994 1.60449Z" fill="#292D32" />
            <path d="M4.58301 3.35449C4.34384 3.35449 4.14551 3.15616 4.14551 2.91699C4.14551 1.70949 5.12551 0.729492 6.33301 0.729492H7.86134C8.10051 0.729492 8.29884 0.927826 8.29884 1.16699C8.29884 1.40616 8.10051 1.60449 7.86134 1.60449H6.33301C5.60967 1.60449 5.02051 2.19366 5.02051 2.91699C5.02051 3.15616 4.82217 3.35449 4.58301 3.35449Z" fill="#292D32" />
            <path d="M11.6943 10.3545C11.4552 10.3545 11.2568 10.1562 11.2568 9.91699C11.2568 9.67783 11.4552 9.47949 11.6943 9.47949C12.3593 9.47949 12.896 8.93699 12.896 8.27783V4.66699C12.896 4.42783 13.0943 4.22949 13.3335 4.22949C13.5727 4.22949 13.771 4.42783 13.771 4.66699V8.27783C13.771 9.42116 12.8377 10.3545 11.6943 10.3545Z" fill="#292D32" />
            <path d="M13.333 5.10461H11.583C10.0313 5.10461 9.39551 4.46877 9.39551 2.91711V1.16711C9.39551 0.992108 9.50051 0.828774 9.66384 0.764608C9.82717 0.694608 10.0138 0.735441 10.1422 0.857941L13.6422 4.35794C13.7647 4.48044 13.8055 4.67294 13.7355 4.83627C13.6713 4.99961 13.508 5.10461 13.333 5.10461ZM10.2705 2.22294V2.91711C10.2705 3.98461 10.5155 4.22961 11.583 4.22961H12.2772L10.2705 2.22294Z" fill="#292D32" />
        </svg>

    )
};
