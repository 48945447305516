import { Provider } from "@ethersproject/providers";
import { apiOrder } from "api/api-order";
import BigNumber from "bignumber.js";
import { ORDER_TYPE } from "config";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import stockAbi from "config/abi/nftShareholderBookAbi.json";
import { useActiveWeb3React } from "hooks";
import { useEffect, useState } from "react";
import multicall from "utils/multicall";
import dayjs from "dayjs";
import { AdminChairperson, ApprovingItem, DataHistoryType, InfoStocksType, StockItem } from "./type";

export const getDataApprover = async (config: string[], chainId: number): Promise<{ listApprover: AdminChairperson[] }> => {
    try {
        const calls = config?.map((dt) => {
            return {
                address: dt,
                name: 'approver',
                params: []
            }
        })
        const resultApprover = await multicall(stockAbi, calls, chainId)
        const mergeData = config?.map((dt, index) => {
            return {
                contractAddress: dt,
                approverAddress: resultApprover[index][0]
            }
        })
        return { listApprover: mergeData }
    } catch (error) {
        console.log(error)
        return { listApprover: [] }
    }
}

export const getInfoStocks = async (config: string[], chainId: number, account: string): Promise<{ infoStocks: InfoStocksType[] }> => {
    try {
        const getSymbol = async () => {
            const calls = config?.map((dt) => {
                return {
                    address: dt,
                    name: 'stockSymbol',
                    params: []
                }
            })
            const resultSymbol = await multicall(stockAbi, calls, chainId)
            const mergeData = config?.map((dt, index) => {
                return {
                    contractAddress: dt,
                    symbol: resultSymbol[index][0]
                }
            })
            return mergeData
        }
        const getListApprover = async (listStock) => {
            const calls = listStock?.map((dt) => {
                return {
                    address: dt?.contractAddress,
                    name: 'approver',
                    params: []
                }
            })
            const resultApprover = await multicall(stockAbi, calls, chainId)
            const mergeData = listStock?.map((dt, index) => {
                return {
                    ...dt,
                    approver: resultApprover[index][0]
                }
            })
            return mergeData
        }
        const getPauseContract = async (listApprover) => {
            const calls = listApprover?.map((dt) => {
                return {
                    address: dt?.contractAddress,
                    name: 'paused',
                    params: []
                }
            })
            const resultPause = await multicall(stockAbi, calls, chainId)
            const mergeData = listApprover?.map((dt, index) => {
                return {
                    ...dt,
                    isPaused: resultPause[index][0]
                }
            })
            return mergeData
        }
        const listStocks = await getSymbol()
        const listApprover = await getListApprover(listStocks)
        const listPause = await getPauseContract(listApprover)
        const finterData = listPause?.filter((dt) => dt?.approver?.toLowerCase() === account?.toLowerCase())
        const sortData = finterData.sort((a, b) => a?.symbol?.toLowerCase().localeCompare(b?.symbol?.toLowerCase()))

        return { infoStocks: sortData }
    } catch (error) {
        console.log(error)
        return { infoStocks: [] }
    }
}

export const getDataApproving = async (config: InfoStocksType[], contractMarketplace: string, chainId: number): Promise<{ dataApproving: ApprovingItem[] }> => {
    try {
        const renderArrStocks = config?.map((dt) => {
            return dt?.contractAddress
        })

        const calls = [
            {
                address: contractMarketplace,
                name: 'getOrderApproves',
                params: [renderArrStocks, !false]
            }
        ]
        const [result] = await multicall(marketplaceAbi, calls, chainId)
        const mergeData = result?._validateOrders?.map((dt) => {
            const findSymbol = config?.find((sbl) => sbl?.contractAddress?.toLowerCase() === dt?.nftAddress?.toLowerCase())
            return {
                orderId: new BigNumber(dt?.orderId?.toString()).toNumber(),
                orderType: new BigNumber(dt?.orderType?.toString()).toNumber(),
                quantity: new BigNumber(dt?.quantity?.toString()).toNumber(),
                receiver: dt?.receiver,
                status: dt?.status,
                tokenId: new BigNumber(dt?.tokenId?.toString()).toNumber(),
                unitPrice: new BigNumber(dt?.unitPrice?.toString()).toNumber(),
                createTimestamp: new BigNumber(dt?.createTimestamp?.toString()).multipliedBy(1000).toNumber(),
                endTimestamp: new BigNumber(dt?.endTimestamp?.toString()).multipliedBy(1000).toNumber(),
                matchedQuantity: new BigNumber(dt?.matchedQuantity?.toString()).toNumber(),
                nftAddress: dt?.nftAddress,
                orderCreator: dt?.orderCreator,
                symbol: findSymbol?.symbol,
                isPaused: findSymbol?.isPaused
            }
        });
        // const checkEndTimestamp = mergeData.filter((item) => item.endTimestamp > dayjs().valueOf());
        return {
            dataApproving: mergeData
        }
    } catch (error) {
        console.log(error)
        return { dataApproving: [] }
    }
}

export const getDataStock = async (stocksAddress: string, chainId: number): Promise<{ stock: StockItem }> => {

    try {
        const calls = [
            {
                address: stocksAddress,
                name: 'ADMIN_ROLE', // 0
                params: []
            },
            {
                address: stocksAddress,
                name: 'approver', // 1
                params: []
            },
            {
                address: stocksAddress,
                name: 'issuePrice', // 2
                params: []
            },
            {
                address: stocksAddress,
                name: 'totalSupply', // 3
                params: [0]
            },
            {
                address: stocksAddress,
                name: 'uri', // 4
                params: [0]
            },
            {
                address: stocksAddress,
                name: 'getShareholders', // 5
                params: []
            },
            {
                address: stocksAddress,
                name: 'stockSymbol', // 6
                params: []
            },
            {
                address: stocksAddress,
                name: 'companyName', // 7
                params: []
            }
        ]

        const result = await multicall(stockAbi, calls, chainId);
        const mergeShareHolders = result[5][0]?.map((dt) => {
            return {
                shareholderAddress: dt?.shareholder,
                amount: new BigNumber(dt?.numberOfShares?.toString()).toString()
            }
        })
        const mergeData = {
            approvedAddress: result[1][0],
            adminRole: result[0][0],
            logo: result[4][0],
            totalRelease: new BigNumber(result[3][0]?.toString()).toString(),
            issuePrice: new BigNumber(result[2][0]?.toString()).toString(),
            shareHolders: mergeShareHolders?.filter((dt) => Number(dt?.amount) > 0),
            symbol: result[6][0],
            companyName: result[7][0],
        }

        return { stock: mergeData }

    } catch (error) {
        console.log(error)
        return { stock: null }
    }
}

export const getTxHash = async (orderIds: number[], chainId: number): Promise<{ validateOrders: any[] }> => {
    try {
        const callsOrders: { orderId: number[] }[] = []
        const MAX_ARR = 8;
        const count = Math.ceil(orderIds.length / MAX_ARR)
        for (let index = 0; index < count; index++) {
            callsOrders.push({
                orderId: orderIds.slice(index * MAX_ARR, (index * MAX_ARR + MAX_ARR))
            })
        }
        const getTxByOrderId = async (orderId: number[]) => {
            try {
                const response = await apiOrder.getApiOrder({ orderId, chainId });
                const mergeData = orderId?.map((dt) => {
                    const findTx = response?.data?.find((item) => item?._id === dt)
                    return {
                        orderId: dt,
                        txHash: findTx?.transaction
                    }
                })
                return mergeData
            } catch (error) {
                console.log(error)
                const mergeData = orderId?.map((dt) => {
                    return {
                        orderId: dt,
                        txHash: ""
                    }
                })
                return mergeData
            }
        }

        const result = await Promise.all(
            callsOrders.map((dt) => {
                return getTxByOrderId(dt?.orderId)
            }),
        )
        return { validateOrders: result.flat() }
    } catch (error) {
        console.log(error)
        return { validateOrders: [] }
    }
}

export const getDataStatistics = async (config: InfoStocksType[], contractMarketplace: string, chainId: number): Promise<{ dataStatistics: ApprovingItem[] }> => {
    try {
        const renderArrStocks = config?.map((dt) => {
            return dt?.contractAddress
        })
        const calls = [
            {
                address: contractMarketplace,
                name: 'getOrderApproves',
                params: [renderArrStocks, false]
            }
        ]
        const [result] = await multicall(marketplaceAbi, calls, chainId)
        const mergeData = result?._validateOrders?.map((dt) => {
            const findSymbol = config?.find((sbl) => sbl?.contractAddress?.toLowerCase() === dt?.nftAddress?.toLowerCase())
            return {
                orderId: new BigNumber(dt?.orderId?.toString()).toNumber(),
                orderType: new BigNumber(dt?.orderType?.toString()).toNumber(),
                quantity: new BigNumber(dt?.quantity?.toString()).toNumber(),
                receiver: dt?.receiver,
                status: dt?.status,
                tokenId: new BigNumber(dt?.tokenId?.toString()).toNumber(),
                unitPrice: new BigNumber(dt?.unitPrice?.toString()).toNumber(),
                createTimestamp: new BigNumber(dt?.createTimestamp?.toString()).multipliedBy(1000).toNumber(),
                endTimestamp: new BigNumber(dt?.endTimestamp?.toString()).multipliedBy(1).toNumber(),
                matchedQuantity: new BigNumber(dt?.matchedQuantity?.toString()).toNumber(),
                nftAddress: dt?.nftAddress,
                orderCreator: dt?.orderCreator,
                symbol: findSymbol?.symbol
            }
        })
        return { dataStatistics: mergeData }
    } catch (error) {
        console.log(error)
        return { dataStatistics: [] }
    }
}


export const getDataApiHistory = async (account: string, provider: Provider | null): Promise<{ dataApiHistory: DataHistoryType[] }> => {
    try {
        const dataHistory = await apiOrder.getApiHistory({ wallet: account?.toLowerCase() });
        const mergeData = dataHistory?.data?.transactions?.map((dt) => {
            return {
                orderId: dt?.orderId,
                tx: dt?.tx,
                createTime: dt?.blockNumber
            }
        })
        return { dataApiHistory: mergeData }
    } catch (error) {
        console.log(error)
        return { dataApiHistory: [] }
    }
}

export const getblockToTimestamp = async (blockNumber: number, provider: Provider | null) => {
    try {
        const { timestamp } = await provider.getBlock(blockNumber)
        return {
            currentTime: timestamp * 1000,
            month: new Date(timestamp * 1000).getMonth() + 1,
            year: new Date(timestamp * 1000).getFullYear()
        }
    } catch (error) {
        console.log(error)
        return {
            currentTime: 0,
            month: 0,
            year: 0
        }
    }
}
