import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
    title: `Trang chủ`,
    image: "https://so-co-dong-git-develop-delta-labs-pro-team.vercel.app/images/reviews/review.png?version=1.1",
    description: 'Sổ cổ đông điện tử là một nền tảng dùng để lưu trữ và quản lý cổ phiếu mà cổ đông đang nắm giữ. Tại đây,cổ đông có thể dễ dàng thực hiện các giao dịch mua, bán và chuyển nhượng cổ phiếu một cách nhanh chóng, tiện lợi và an toàn.',
}

interface PathList {
    paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
    defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
    return {
        paths: {
            '/': { title: 'Trang chủ' },
        },
        defaultTitleSuffix: 'Sổ cổ đông điện tử',
    }
}

export const getCustomMeta = memoize(
    (path: string, t: ContextApi['t'], _: string): PageMeta => {
        const pathList = getPathList(t)
        const pathMetadata =
            pathList.paths[path] ??
            pathList.paths[
                Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]
            ]

        if (pathMetadata) {
            return {
                title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
                ...(pathMetadata.description && { description: pathMetadata.description }),
            }
        }
        return null
    },
    (path, t, locale) => `${path}#${locale}`,
)
