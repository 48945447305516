import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { TitleTableMobile, ValueTableMobile, WrapperCopy, WrapperScroll } from 'components'
import { BigNumber } from 'bignumber.js';
import { formatNumberPayment } from 'config';

interface Props {
    issuePrice: string,
    listHolders: {
        shareholderAddress: string,
        amount: string
    }[]
}

export const TableMobile: React.FC<Props> = ({
    issuePrice, listHolders
}) => {
    const dataShareholders = listHolders?.map((dt) => {
        return {
            holderAddress: dt?.shareholderAddress,
            amount: dt?.amount,
            totalValueOfStock: new BigNumber(dt?.amount).multipliedBy(issuePrice).toString()
        }
    })
    return (
        <WrapperScroll className="max-h-80">

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                {
                    dataShareholders?.map((item, index) => (
                        <LazyLoadComponent>
                            <div
                                className="rounded-lg p-4 grid gap-3"
                                style={{ border: "1px solid #E1E8E5" }}
                            >
                                <div className="flex items-center justify-between">
                                    <TitleTableMobile title="STT" />
                                    <ValueTableMobile value={index + 1} />
                                </div>
                                <div className="flex items-center justify-between">
                                    <TitleTableMobile title="Địa chỉ ví" />
                                    <div className="flex items-center gap-2">
                                        <WrapperCopy value={item.holderAddress} />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <TitleTableMobile title="Số lượng cổ phiếu" />
                                    <ValueTableMobile value={formatNumberPayment(Number(item.amount))} />
                                </div>
                                <div className="flex items-center justify-between">
                                    <TitleTableMobile title="Tổng giá trị cổ phiếu" />
                                    <ValueTableMobile value={formatNumberPayment(Number(item.totalValueOfStock))} />
                                </div>
                            </div>

                        </LazyLoadComponent>
                    ))
                }
            </div>
        </WrapperScroll>
    )
}
